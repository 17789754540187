import { FluidObject } from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

export const useEbookImage = (): FluidObject => {
  const { file } = useStaticQuery(graphql`
    query EbookImage {
      file(relativePath: { eq: "Ebook/hromada_ebook.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return file.childImageSharp.fluid;
};
