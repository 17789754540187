import { Box, Container } from '@material-ui/core';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';

export interface WhyWithMeHeroProps {
  image: IGatsbyImageData;
  hook: string;
  text: string;
  finisher: string;
}

export const WhyWithMeHero = (props: WhyWithMeHeroProps) => {
  const { image, hook, text, finisher } = props;
  return (
    <Box
      position={'relative'}
      height={{
        xs: '450px',
        md: '700px',
      }}
      overflow="hidden"
    >
      <Box
        className="image"
        position="absolute"
        display={'flex'}
        overflow="hidden"
        top={0}
        bottom="-300px"
        right={{
          xs: -200,
          md: 0,
        }}
        left="0"
        alignItems={'center'}
      >
        <GatsbyImage
          style={{
            height: '100%',
          }}
          imgStyle={{
            height: '100%',
          }}
          image={image}
          alt={'Why with me image?'}
        />
      </Box>
      <Box
        className="text"
        position="absolute"
        display={'flex'}
        top="0"
        bottom="0"
        right="0"
        left="0"
      >
        <Container>
          <Box display={'flex'} flex="1" width={'100%'} height="100%">
            <Box className="" display="flex" flex="1 1 auto"></Box>
            <Box
              flex="1"
              display="flex"
              flexDirection={'column'}
              justifyContent="start"
              fontWeight={600}
              py="8rem"
            >
              <Box
                fontSize={{
                  xs: '3rem',
                  md: '5rem',
                }}
              >
                {hook}
              </Box>
              <Box
                fontWeight={500}
                fontSize={{
                  xs: '1.8rem',
                  md: '2.8rem',
                }}
                mt={{
                  xs: 2,
                  md: 3,
                }}
              >
                {text.split('\n').map((item) => (
                  <Box>{item}</Box>
                ))}
              </Box>
              <Box
                fontSize={{
                  xs: '2.5rem',
                  md: '3.5rem',
                }}
                mt={{
                  xs: 2,
                  md: 3,
                }}
              >
                {finisher}
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};
