import { FluidObject } from 'gatsby-image';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import {
  Section,
  SectionHeaderWithBgImage,
  ServiceSectionDescriptionAndSendAppointment,
} from '../../components';
import {
  ServiceReference,
  ServiceReferenceProps,
} from '../../components/Services/ServiceReference/ServiceReference';
import { theme } from '../../theme/theme';

export interface ServiceSectionProps {
  className?: string;
  title: string;
  shortText: string;
  image: FluidObject;
  content: string;

  reference: ServiceReferenceProps;
}

const ServiceDescriptionTitle = styled.h1`
  margin-bottom: 30px;
  font-size: 42px;
`;

const ServiceSectionInner = (props: ServiceSectionProps) => {
  const { title, image, content, reference } = props;
  return (
    <Section className={props.className} id={title}>
      <SectionHeaderWithBgImage image={image} title={title} />
      <ServiceSectionDescriptionAndSendAppointment
        serviceDescription={
          <>
            <ServiceDescriptionTitle>{title}</ServiceDescriptionTitle>
            <ReactMarkdown children={content} />
          </>
        }
        serviceRightPanel={reference && <ServiceReference {...reference} />}
      />
    </Section>
  );
};

export const ServiceSection = styled(ServiceSectionInner)`
  p {
    margin-bottom: 10px;
  }

  ul {
    list-style: none;
  }

  li {
    color: ${theme.color.gray};
    &::before {
      content: '•';
      font-size: 20px;
      color: ${theme.color.primaryDark};
      display: inline-block;
      width: 1em;
      position: absolute;
      margin-left: -1em;
    }
  }
`;
