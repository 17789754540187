import { SEO, theme } from '@hromada-mono/hromada';
import { Container } from '@material-ui/core';
import { graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { TextSection } from '../components/Shared/Layout/components/TextSection/TextSection';
import Layout from '../components/Shared/Layout/Layout';

const BlogPost = styled.div`
  margin-top: 50px;

  img {
    width: 100%;
  }

  h1 {
    margin-bottom: 20px;
    font-size: 48px;

    @media (max-width: ${theme.breakpoints.md}) {
      font-size: 22px;
    }
  }
`;

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { strapiBlog } = data; // data.markdownRemark holds your post data
  return (
    <Layout>
      <SEO title={strapiBlog.title} />
      <GatsbyImage
        style={{
          maxHeight: 500,
        }}
        fluid={strapiBlog.thumbnail.localFile.childImageSharp.fluid}
      />

      <Container>
        <BlogPost>
          <h1>{strapiBlog.title}</h1>
          <ReactMarkdown
            transformImageUri={(uri) =>
              uri.startsWith('http')
                ? uri
                : `https://es.backoffice.branohromada.com${uri}`
            }
            children={strapiBlog.content}
          />
        </BlogPost>
        {strapiBlog?.cta?.title && (
          <div className="cta">
            <TextSection
              title={strapiBlog?.cta?.title}
              description={strapiBlog?.cta?.text}
              button={strapiBlog?.cta?.button?.label}
              buttonUrl={strapiBlog?.cta?.button?.link}
            />
          </div>
        )}
      </Container>
    </Layout>
  );
}

export const pageQuery = graphql`
  query pageData($id: String!, $language: String!) {
    strapiBlog(id: { eq: $id }) {
      title
      created_at(formatString: "MMMM DD, YYYY")
      content
      cta {
        title
        text
        button {
          label
          link
        }
      }
      thumbnail {
        localFile {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
