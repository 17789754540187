import { useStaticQuery, graphql } from "gatsby";

export const useRequestedServicesImages = () => {
  const { person, rocket, chat } = useStaticQuery(graphql`
    query MostRequestedServicesImages {
      chat: file(relativePath: { eq: "Hero/chat.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      person: file(relativePath: { eq: "Hero/person.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      rocket: file(relativePath: { eq: "Hero/rocket.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return {
    chat: chat.childImageSharp.fluid,
    person: person.childImageSharp.fluid,
    rocket: rocket.childImageSharp.fluid
  };
};
