import { AccordionElement, theme } from '@hromada-mono/hromada';
import { Box, BoxProps } from '@material-ui/core';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { AccordionItem } from '../../models/Accordion/accordion-item.model';

export interface AccordionBoxProps extends BoxProps {
  title: string;
  description: string;
  items?: AccordionItem[];
}

export const AccordionBox = (props: AccordionBoxProps) => {
  const { title, description, items = [] } = props;
  return (
    <Box bgcolor={theme.color.sectionBg} {...props}>
      <h3>{title}</h3>
      <Box marginTop="10px">
        <ReactMarkdown>{description}</ReactMarkdown>
      </Box>
      <AccordionElement items={items} />
    </Box>
  );
};
