import GatsbyImage, { FluidObject } from 'gatsby-image';
import { Link } from 'gatsby-plugin-react-i18next';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { theme } from '../../theme/theme';
import { ViewMoreButton } from '../Button/ViewMore/ViewMoreButton';
import {
  Actions,
  ArticleContent,
  ImageWrapper,
  LeftSide,
  RightSide,
} from './components/styles';

export interface ArticleBannerProps {
  className?: string;
  link: string;
  title: string;
  content?: string;
  viewMore: {
    label: string;
    link: string;
  };
  image: FluidObject;
}

const ArticleInner = (props: ArticleBannerProps) => {
  const { title, image, link, viewMore } = props;

  return (
    <Link className={props.className} to={link}>
      <ArticleContent container justify="space-between">
        <LeftSide item md={4} sm={12} xs={12}>
          <ImageWrapper>
            <GatsbyImage
              style={{
                height: '100%',
              }}
              fluid={image}
            />
          </ImageWrapper>
        </LeftSide>

        <RightSide
          item
          md={8}
          style={{
            position: 'relative',
          }}
        >
          <h3>{title}</h3>
          <div className="html-re">
            <ReactMarkdown children={props.content || ''} />
          </div>
          <div className="readmore" />
          <Actions>
            <ViewMoreButton to={viewMore.link}>{viewMore.label}</ViewMoreButton>
          </Actions>
        </RightSide>
      </ArticleContent>
    </Link>
  );
};

export const ArticleBanner = styled(ArticleInner)`
  color: ${theme.color.gray};
  transition: all 230ms ease-in-out;
  display: flex;

  &:hover {
    transform: scale(1.005);
  }

  h3 {
    margin-bottom: 15px;
  }

  .html-re {
    max-height: 486px;
    overflow: hidden;
  }
  .readmore {
    position: absolute;
    bottom: 70px;
    left: 0;
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 30px 0;
    height: 150px;

    background-image: linear-gradient(to bottom, transparent, #f5f5f5);
  }
`;
