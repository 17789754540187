import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { emailRegex } from "../../../../lib/consts";
import { Button } from "../../Button/Primary/Button";
import { FormItem, FormActions } from "../../FormSection/components/Form";
import { rgba } from "polished";

export interface EmailComponentProps {
  className?: string;
}

const EmailComponentInner = (props: EmailComponentProps) => {
  const { register, handleSubmit, watch, errors } = useForm();
  const onSubmit = async (data: any) => {
    const resp = await fetch("https://jsonplaceholder.typicode.com/posts", {
      method: "GET"
    });
  };

  const { t } = useTranslation("translations");
  return (
    <div className={props.className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ItemsWrapper>
          <FormItem>
            <input
              placeholder="Email"
              name="email"
              ref={register({
                required: true,
                pattern: emailRegex
              })}
            />
            {errors.email && (
              <span>{t("contactFormSection.errors.email")}</span>
            )}
          </FormItem>

          <FormItem className="form-item_email">
            <input
              type="checkbox"
              name="agreement"
              className="email_checkbox"
            />
            <label htmlFor="agreement" className="email_checkbox-label">
              {t("ebookPage.emailForm.emailAgreement")}
            </label>
          </FormItem>
        </ItemsWrapper>
        <FormActions>
          <Button onClick={handleSubmit(onSubmit)} type="secondary">
            {t("ebookPage.emailForm.button")}
          </Button>
        </FormActions>
      </form>
    </div>
  );
};

export const EmailComponent = styled(EmailComponentInner)`
  padding: 35px 25px;

  form {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  input {
    display: flex;
    margin-bottom: 10px;
    flex: 1;
    padding: 1.5rem;
    border-radius: 3px;
    border: 2px solid ${props => props.theme.color.primaryDark};
    font-size: 1.6rem;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    ::placeholder {
      color: ${props => rgba(props.theme.color.primaryDark, 0.5)};
      opacity: 1; /* Firefox */
    }
  }

  .form-item_email {
    flex-direction: row;
    justify-content: flex-start;

    .email_checkbox {
      min-width: 20px;
      min-height: 20px;
      display: block;
      margin-right: 10px;
    }

    .email_checkbox-label {
      color: white;
      display: inline;
    }
  }
`;
const ItemsWrapper = styled.div`
  background-color: ${props => props.theme.color.primaryDark};
  padding: 35px 25px;
  padding-bottom: 0;
  border-radius: 5px;
  margin-bottom: 30px;
`;
