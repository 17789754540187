import React from "react";
import styled from "styled-components";
import { Section } from "../Shared/Layout/components/Section/Section";
import { NotFoundIntro } from "./components/NotFoundIntro";

export interface NotFoundMainSectionProps {
  className?: string;
}
const NotFoundMainSectionInner = (props: NotFoundMainSectionProps) => {
  return (
    <Section className={props.className}>
      <NotFoundIntro />
    </Section>
  );
};

export const NotFoundMainSection = styled(NotFoundMainSectionInner)`
  padding-bottom: 45px;
  p {
    text-align: center;
  }
`;
