/**
 * Generate route for a single event
 * @param slug
 * @returns
 */
export const generateEventRoute = (
  slug: string,
  locale: 'en' | 'es' = 'en'
) => {
  return {
    en: `/events/${slug}`,
    es: `/eventos/${slug}`,
  }[locale];
};
