import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import React from "react";
import styled from "styled-components";
import { TopLeftGrid } from "./Left/TopLeftGrid";
import { TopRightGrid } from "./Right/TopRightGrid";

export interface TopFooterProps {
  className?: string;
}

const TopFooterInner = (props: TopFooterProps) => {
  return (
    <div className={props.className}>
      <Container
        style={{
          padding: 4 * 8
        }}
      >
        <Grid container justify="space-between" spacing={8}>
          <TopLeftGrid />
          <TopRightGrid />
        </Grid>
      </Container>
    </div>
  );
};

export const TopFooter = styled(TopFooterInner)`
  background-color: ${props => props.theme.color.primaryDark};
  padding: 25px 0;
`;
