import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import styled from 'styled-components';
import EmailIcon from '../../../assets/icons/email_kontakt_icon.svg';
import PhoneIcon from '../../../assets/icons/phone_kontakt_icon.svg';
import {
  ContactBannerItem,
  ContactBannerItemProps,
} from '../../../components/ContactBannerItem/ContactBannerItem';
import { SectionTitle } from '../../../components/SectionTitle/SectionTitle';
import { muiTheme, theme } from '../../../theme/theme';
import { TopSubTitle } from './styled';

export type ContactItem = Omit<ContactBannerItemProps, 'icon'>;

export interface RightContactInfoProps {
  className?: string;
  title: string;
  tagTitle: string;
  phone: ContactItem;
  email: ContactItem;
}

const RightContactInfoInner = (props: RightContactInfoProps) => {
  const { t } = useTranslation('translations');

  const { tagTitle, title, phone, email } = props;

  return (
    <div className={props.className}>
      <TopSubTitle>{tagTitle}</TopSubTitle>
      <SectionTitle textAlign="left">{title}</SectionTitle>
      <ContactBannerItem
        {...phone}
        icon={<img src={PhoneIcon} className="icon" />}
      />
      <ContactBannerItem
        {...email}
        icon={<img src={EmailIcon} className="icon" />}
      />
    </div>
  );
};

export const RightContactInfo = styled(RightContactInfoInner)`
  .icon {
    font-size: 50px;
    color: ${theme.color.gray};
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
    //transform: scale(2);

    ${muiTheme.breakpoints.down('xs')} {
      width: 20px;
    }
  }

  ${TopSubTitle} {
    font-size: 22px;
    margin-bottom: 10px;
  }
`;
