import { Container, Grid } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { Section } from "../../Shared/Layout/components/Section/Section";
import { muiTheme } from "../../Shared/Theme/Theme";
import { ColorfulParagraph } from "./components/ColorfulParagraph";
import { useParagraphs } from "./hooks/useParagraphs";

export interface CooperationColorfulParagraphsSectionProps {
  className?: string;
}

const CustomGrid = styled(Grid)<{
  justifyContent: string;
}>`
  justify-content: ${props => props.justifyContent};
  ${muiTheme.breakpoints.down("xs")} {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

const CooperationColorfulParagraphsSectionInner = (
  props: CooperationColorfulParagraphsSectionProps
) => {
  const paragraphs = useParagraphs();
  return (
    <Section className={props.className}>
      <Container>
        <Grid container justify="space-around">
          {paragraphs.map((item, index) => (
            <CustomGrid
              container
              item
              key={index}
              sm={6}
              xs={12}
              justifyContent={item.justifyContent}
            >
              <ColorfulParagraph
                key="index"
                text={item.paragraph}
                color={item.color}
              />
            </CustomGrid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export const CooperationColorfulParagraphsSection = styled(
  CooperationColorfulParagraphsSectionInner
)`
  & .colorful-paragraph_wrapper {
  }
`;
