import React from 'react';
import styled from 'styled-components';

export interface FreeVideoItemProps {
  className?: string;
  title: string;
  text: string;
  url: string;
}

const FreeVideoItemInner = (props: FreeVideoItemProps) => {
  const { title, text, url } = props;
  return (
    <div className={props.className}>
      <VideoIntro>
        <h3>{title}</h3>
        <p>{text}</p>
      </VideoIntro>
      <div className="video">
        <iframe
          src={url}
          title={title}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </div>
    </div>
  );
};

export const VideoItem = styled(FreeVideoItemInner)`
  height: 100%;
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  justify-content: space-between;

  .video {
    height: 415px;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
`;
const VideoIntro = styled.div`
  margin-bottom: 20px;
  h3 {
    margin-bottom: 20px;
  }
`;
