import { Checkbox, CheckboxProps } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { rgba } from "polished";
import qs from "query-string";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import Swal from "sweetalert2";
import { emailRegex } from "../../../../lib/consts";
import { Button } from "../../Button/Primary/Button";
import { muiTheme, theme } from "../../Theme/Theme";

export interface FormProps {
  className?: string;
}

export const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;

  ${muiTheme.breakpoints.down("sm")} {
    ${Button} {
      display: flex;
      flex: 1;
      justify-content: center;
    }
  }
`;

export const FormItem = styled.div`
  display: flex;
  margin-bottom: 20px;
  flex-flow: column;
`;

const GreenCheckbox = withStyles({
  root: {
    color: "white",
    "&$checked": {
      color: theme.color.primary,
    },
  },
  checked: {},
})((props: CheckboxProps) => (
  <Checkbox
    labelStyle={{
      color: "red",
    }}
    color="default"
    {...props}
  />
));

const CheckboxLabel = styled.span`
  font-size: 14px;
  color: white;
`;

const CheckboxIcon = styled.span``;

const FormInner = (props: FormProps) => {
  const { register, handleSubmit, watch, reset, errors } = useForm();
  const [recaptchaResp, setRecaptchaResp] = useState<any>(null);

  const onSubmit = async (data: any) => {
    axios({
      url: window.location.pathname,
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: qs.stringify({
        ...data,
        // "g-recaptcha-response": recaptchaResp
      }),
    }).then((resp) => {
      Swal.fire({
        title: "Formulario enviado con éxito",
        icon: "success",
      });
      reset();
    });
  };
  const { t } = useTranslation("translations");
  return (
    <form
      className={props.className}
      name="Contact Form"
      method="POST"
      data-netlify="true"
    >
      <input
        ref={register({
          required: true,
        })}
        type="hidden"
        name="form-name"
        value="Contact Form"
      />

      <FormItem>
        <input
          placeholder={`* ${t("contactFormSection.formItems.name")}`}
          required
          name="name"
          ref={register({
            required: true,
          })}
        />
        {errors.name && <span>{t("contactFormSection.errors.required")}</span>}
      </FormItem>

      <FormItem>
        <input
          placeholder={`${t("contactFormSection.formItems.mobile")}`}
          name="mobile"
          ref={register({})}
        />
        {errors.mobile && (
          <span>{t("contactFormSection.errors.required")}</span>
        )}
      </FormItem>

      <FormItem>
        <input
          placeholder={`* ${t("contactFormSection.formItems.email")}`}
          name="email"
          ref={register({
            required: true,
            pattern: emailRegex,
          })}
        />
        {errors.email && <span>{t("contactFormSection.errors.email")}</span>}
      </FormItem>

      <FormItem>
        <textarea
          rows={5}
          placeholder={`* ${t("contactFormSection.formItems.message")}`}
          name="message"
          ref={register({
            required: true,
          })}
        />
        {errors.message && (
          <span>{t("contactFormSection.errors.required")}</span>
        )}
      </FormItem>

      <FormItem>
        <FormControlLabel
          style={{
            color: theme.color.gray,
          }}
          control={<GreenCheckbox required name="agreement" />}
          label={
            <CheckboxLabel className="test">
              Acepto el procesamiento de datos personales para poder contactarme
              y enviar avisos comerciales
            </CheckboxLabel>
          }
        />
      </FormItem>
      {/* 
      <ReCAPTCHA
        onChange={re => {
          setRecaptchaResp(re);
        }}
        sitekey={"6LdzTRoaAAAAAHtulyT61yQyOdLB5QFCpPDexMDr"}
      /> */}
      <FormActions>
        <Button onClick={handleSubmit(onSubmit)} type="secondary">
          {t("contactFormSection.formItems.submitButton")}
        </Button>
      </FormActions>
    </form>
  );
};

export const Form = styled(FormInner)`
  display: flex;
  border-radius: 5px;
  flex-flow: column;
  padding: 30px;
  background: ${theme.color.primaryDark};
  color: white;

  .Checkbox {
    display: flex;
    flex-flow: row;
    input {
      margin-right: 10px;
      flex: 0;
    }
  }

  .MuiSvgIcon-root {
    font-size: 2.5rem;
  }

  input,
  textarea {
    border-radius: 5px;
    display: flex;
    margin-bottom: 10px;
    flex: 1;
    padding: 1.5rem;
    border: 2px solid ${(props) => props.theme.color.primaryDark};
    font-size: 1.6rem;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    ::placeholder {
      color: ${(props) => rgba(props.theme.color.primaryDark, 0.5)};
      opacity: 1; /* Firefox */
    }
  }
`;
