import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import { TextSection } from "../../Shared/Layout/components/TextSection/TextSection";

export interface EbookOrientedOnSolutionsSectionProps {
  className?: string;
}

const EbookOrientedOnSolutionsSectionInner = (
  props: EbookOrientedOnSolutionsSectionProps
) => {
  const { t } = useTranslation("translations");
  return (
    <TextSection
      className={props.className}
      title={t("solutionsOrientedBanner.title")}
      description={t("solutionsOrientedBanner.text")}
    />
  );
};

export const EbookOrientedOnSolutionsSection = styled(
  EbookOrientedOnSolutionsSectionInner
)``;
