import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import styled from 'styled-components';
import { Form, FormProps, Section } from '../../components';
import {
  FormSectionHeader,
  FormSectionHeaderProps,
} from './components/FormSectionHeader';
import {
  RightContactInfo,
  RightContactInfoProps,
} from './components/RightContactInfo';

export interface FormSectionProps {
  className?: string;
  form: FormProps;
  contact: RightContactInfoProps;
  header: FormSectionHeaderProps;
}

const FormSectionInner = (props: FormSectionProps) => {
  const { form, contact, header } = props;
  return (
    <Section className={props.className}>
      <Container>
        <FormSectionHeader {...header} />
        <Grid container spacing={6}>
          <Grid item lg={8} md={12}>
            <Form {...form} />
          </Grid>
          <Grid item lg={4} md={12} container alignItems="flex-start">
            <RightContactInfo {...contact} />
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

export const FormSection = styled(FormSectionInner)`
  max-width: 100vw;
  overflow: hidden;
`;
