import React from "react";
import styled from "styled-components";
import { BottomFooter } from "./components/Bottom/BottomFooter";
import { TopFooter } from "./components/Top/TopFooter";

export interface FooterProps {
  className?: string;
}

const FooterInner = (props: FooterProps) => {
  return (
    <footer className={props.className}>
      <TopFooter />
      <BottomFooter />
    </footer>
  );
};

export const Footer = styled(FooterInner)`
  margin-top: 18rem;
`;
