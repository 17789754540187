import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { routes } from "../../../../../../../../lib/routes";
import { theme } from "../../../../../../Theme/Theme";

export interface FooterNavigationProps {
  className?: string;
}

const NavLink = styled(Link)`
  color: ${theme.color.white};
  margin: 4px 10px;
  font-weight: 600;
  transition: all 230ms ease-in-out;
  font-size: 16px;

  &.active,
  &:hover {
    color: ${(props) => props.theme.color.primary};
  }
`;

const FooterNavigationInner = (props: FooterNavigationProps) => {
  return (
    <div className={props.className}>
      {Object.values(routes)
        .filter(
          (route) => !route.hideInMenu && route.to !== "/recursos-gratuitos/"
        )
        .map((route, index) => (
          <NavLink key={index} activeClassName="active" to={route.to}>
            {route.label}
          </NavLink>
        ))}
    </div>
  );
};

export const FooterNavigation = styled(FooterNavigationInner)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
