import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import styled from 'styled-components';
import {
  ImageLinkCard,
  ImageLinkCardProps,
} from '../../components/ImageLinkCard/ImageLinkCard';
import { Section } from '../../components/Section/Section';

export interface ServicesCategoriesProps {
  className?: string;
  cards: ImageLinkCardProps[];
}

const ServicesCategoriesInner = (props: ServicesCategoriesProps) => {
  const { cards } = props;
  return (
    <Section className={props.className}>
      <Container>
        <Grid container justify="center" alignItems="center" spacing={4}>
          {cards.map((card) => (
            <Grid item lg={3} md={6} xs={12} key={card.title}>
              <ImageLinkCard {...card} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export const ImageCardSection = styled(ServicesCategoriesInner)``;
