import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import { Section } from "../../Shared/Layout/components/Section/Section";
import { SectionTitle } from "../../Shared/SectionTitle/SectionTitle";
import { TherapyItem } from "./components/TherapyItem";
import { useTherapyItems } from "./hooks/useTherapyItems";

export interface ProcessOfCooperationSectionProps {
  className?: string;
}

const ProcessOfCooperationSectionInner = (
  props: ProcessOfCooperationSectionProps
) => {
  const { t } = useTranslation("translations");
  const therapyItems = useTherapyItems();

  return (
    <Section darkBg className={props.className}>
      <Container>
        <SectionTitle textAlign="center">
          {t("whyWithMePage.theProcessOfCooperation.title")}
        </SectionTitle>
        <Grid container direction="column">
          {therapyItems.map((item, index) => (
            <TherapyItem key={index} number={index + 1} {...item} />
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export const ProcessOfCooperationSection = styled(
  ProcessOfCooperationSectionInner
)``;
