import Container from '@material-ui/core/Container';
import React from 'react';
import styled from 'styled-components';

export interface TopHeaderProps {
  className?: string;
  title: string;
}

const TopHeaderInner = (props: TopHeaderProps) => {
  const { title } = props;
  return (
    <div className={props.className}>
      <Container>
        <h1>{title}</h1>
      </Container>
    </div>
  );
};

export const TopHeader = styled(TopHeaderInner)`
  background-color: ${(props) => props.theme.color.sectionBg};
  padding: 40px 0;

  h1 {
    text-transform: uppercase;
  }
`;
