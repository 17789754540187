export const COLOR = {
  WHITE: "#ffffff",
  BLACK: "#000000",
  REBECCA_PURPLE: "#663399",
  BLUE: "#1a58cc"
};

export const languages = [
  {
    label: "SK",
    link: "https://branohromada.sk",
    longLabel: "Slovak"
  },
  {
    label: "ENG",
    link: "https://branohromada.com",
    longLabel: "English"
  },
  {
    label: "ES",
    current: true,
    link: "https://branohromada.es",
    longLabel: "Spanish"
  }
];

export const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
