import styled, { css } from "styled-components";
import { theme } from "../../theme/theme";

export interface SectionProps {
  darkBg?: boolean;
}

export const Section = styled.section<SectionProps>`
  margin-top: 70px;
  max-width: 100vw;
  overflow: hidden;
  ${({ darkBg = false }) =>
    darkBg &&
    css`
      background-color: ${theme.color.sectionBg};
      padding: 50px 0;
    `}
`;
