import { Checkbox, CheckboxProps, withStyles } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { muiTheme, theme } from '../../../theme/theme';
import { Button } from '../../Button/Primary/Button';

export const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;

  ${muiTheme.breakpoints.down('sm')} {
    ${Button} {
      display: flex;
      flex: 1;
      justify-content: center;
    }
  }
`;

export const FormItem = styled.div`
  display: flex;
  margin-bottom: 20px;
  flex-flow: column;
`;

export const HromandaCheckBox = withStyles({
  root: {
    color: 'white',
    '&$checked': {
      color: theme.color.primary,
    },
  },
  checked: {},
})((props: CheckboxProps) => (
  <Checkbox
    labelStyle={{
      color: 'red',
    }}
    color="default"
    {...props}
  />
));

export const CheckboxLabel = styled.span`
  font-size: 14px;
  color: white;
`;
