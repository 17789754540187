import { Container } from '@material-ui/core';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { muiTheme, theme } from '../../theme/theme';

export interface OrganizationReferenceProps {
  background: any;
  title: string;
  image: FluidObject;
  content: string;
}

export const OrganizationReference = (props: OrganizationReferenceProps) => {
  const { background = '', title, image, content } = props;
  return (
    <Reference background={background}>
      <Container>
        <div className="inner">
          <div className="image">
            <GatsbyImage
              style={{
                maxHeight: '200px',
                height: '200px',
              }}
              imgStyle={{ objectFit: 'contain' }}
              className={`image-wrapper ${title}`}
              fluid={image}
            />
          </div>
          <div className="detail">
            <header>
              <h3>{title}</h3>
            </header>
            <div className="content">
              <ReactMarkdown>{content}</ReactMarkdown>
            </div>
          </div>
        </div>
      </Container>
    </Reference>
  );
};

export const Reference = styled.article<{ background: boolean }>`
  display: flex;
  padding: 60px 0;
  position: relative;
  flex-flow: column;
  background: ${(props) =>
    props.background ? theme.color.sectionBg : 'white'};

  .inner {
    display: flex;
    flex-flow: column;
  }

  .image {
    padding: 40px;
    position: relative;
  }

  header {
    margin-bottom: 20px;
  }

  .detail {
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
    width: 100%;
  }

  ${muiTheme.breakpoints.up('md')} {
    .inner {
      display: flex;
      flex-flow: row;
    }

    .image-wrapper {
      width: 80%;
      margin: auto;
    }

    .image {
      width: 50%;
      text-align: center;
    }

    .detail {
      display: flex;
      flex-flow: column;
      flex: 1 1 auto;
      width: 50%;
    }
  }
`;
