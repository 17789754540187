import {
  ImageAccordionSection,
  SEO,
  ServicesSection,
  TextSection,
} from '@hromada-mono/hromada';
import { graphql } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BlogSectionControl } from '../../components/Home/BlogSectionControl';
import { FormSectionControl } from '../../components/Home/FormSectionControl';
import { useCoachImage } from '../../components/Services/InspirationalWorkhops/WhatCanYouExpectSection/hooks/useCoachImage';
import { TopHeader } from '../../components/Shared/Layout/components/TopHeader/TopHeader';
import Layout from '../../components/Shared/Layout/Layout';
import { routes } from '../../lib/routes';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    data: strapiCoaching {
      title {
        title
        description
      }
      imageAccorion {
        title
        description
        accordion {
          title: label
          content
        }
        image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }

      services {
        title
        text
        shortText
        reference {
          title
          text
          image {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

const ServicesCoachingPage = ({ data: { data } }) => {
  const image = useCoachImage();
  const { t } = useTranslation();

  return (
    <Layout>
      <TopHeader title="Servicios / Coaching breve" />
      <SEO title="Coaching breve" />

      <TextSection {...data.title} />

      <ImageAccordionSection
        title={data?.imageAccorion.title}
        description={data?.imageAccorion.description}
        items={data.imageAccorion.accordion}
        image={data.imageAccorion.image.localFile.childImageSharp.fluid}
      />

      <ServicesSection
        services={data.services.map((service) => ({
          title: service.title,
          content: service.text,
          shortText: service.shortText,
          image: service.image.localFile.childImageSharp.fluid,
          reference: {
            image: service.reference.image.localFile.childImageSharp.fluid,
            title: service.reference.title,
            content: service.reference.text,
            button: {
              label: t('buttons.requestAppointment'),
              link: routes.contact.to,
            },
          },
        }))}
      />

      <BlogSectionControl />
      <FormSectionControl />
    </Layout>
  );
};

export default ServicesCoachingPage;
