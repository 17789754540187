import Container from "@material-ui/core/Container";
import React from "react";
import styled from "styled-components";
import { SectionTitle } from "../../Shared/SectionTitle/SectionTitle";

export interface NotFoundIntroProps {
  className?: string;
}

const NotFoundIntroInner = (props: NotFoundIntroProps) => {
  return (
    <Container className={props.className}>
      <SectionTitle textAlign="center">
        We can`t seem to find the page you`re logging for
      </SectionTitle>
      <p>But you can still survey my service, or enjoy the view :`)` </p>
    </Container>
  );
};

export const NotFoundIntro = styled(NotFoundIntroInner)`
  p {
    text-align: center;
  }
`;
