import React from 'react';
import styled from 'styled-components';

export interface ClassicHeroSectionProps {
  image: React.ReactNode;
  title: string;
}

const ClassicHero = styled.section`
  position: relative;
  height: 500px;
  z-index: 0;

  .image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 10;
  }

  .text {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    width: 60%;
    margin: 0 auto;
    height: 100%;
    z-index: 20;
    color: white;
    font-size: 5.4rem;
    font-weight: 700;

    @media (max-width: 768px) {
      font-size: 3.6rem;
    }
  }

  @media (max-width: 768px) {
    height: 300px;
  }
`;

export const ClassicHeroSection = (props: ClassicHeroSectionProps) => {
  const { image, title } = props;
  return (
    <ClassicHero>
      <div className="image">{image}</div>
      <div className="text">{title}</div>
    </ClassicHero>
  );
};
