import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { rgba } from 'polished';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Button } from '..';
import { emailRegex } from '../../consts/email-regex';
import { theme } from '../../theme/theme';
import {
  CheckboxLabel,
  FormActions,
  FormItem,
  HromandaCheckBox,
} from './components/styled';

export interface FormProps {
  className?: string;
  placeholders: {
    name: string;
    mobile: string;
    email: string;
    message: string;
  };
  checkboxText: string;
  submitLabel: string;
  onSubmit: (values: any) => Promise<void>;
}

const FormInner = (props: FormProps) => {
  const { register, handleSubmit, watch, reset, errors } = useForm();
  const { placeholders, onSubmit } = props;

  const onFormSubmit = useCallback(async (values: any) => {
    await props.onSubmit(values);
    reset();
  }, []);

  const { t } = useTranslation('translations');

  return (
    <form
      className={props.className}
      name="Contact Form"
      method="POST"
      data-netlify="true"
    >
      <input
        ref={register({
          required: true,
        })}
        type="hidden"
        name="form-name"
        value="Contact Form"
      />

      <FormItem>
        <input
          placeholder={`* ${placeholders.name}`}
          required
          name="name"
          ref={register({
            required: true,
          })}
        />
        {errors.name && <span>{t('contactFormSection.errors.required')}</span>}
      </FormItem>

      <FormItem>
        <input
          placeholder={placeholders.mobile}
          name="mobile"
          ref={register({})}
        />
        {errors.mobile && (
          <span>{t('contactFormSection.errors.required')}</span>
        )}
      </FormItem>

      <FormItem>
        <input
          placeholder={`* ${placeholders.email}`}
          name="email"
          ref={register({
            required: true,
            pattern: emailRegex,
          })}
        />
        {errors.email && <span>{t('contactFormSection.errors.email')}</span>}
      </FormItem>

      <FormItem>
        <textarea
          rows={5}
          placeholder={`* ${placeholders.message}`}
          name="message"
          ref={register({
            required: true,
          })}
        />
        {errors.message && (
          <span>{t('contactFormSection.errors.required')}</span>
        )}
      </FormItem>

      <FormItem>
        <FormControlLabel
          style={{
            color: theme.color.gray,
          }}
          control={<HromandaCheckBox required name="agreement" />}
          label={
            <CheckboxLabel className="test">{props.checkboxText}</CheckboxLabel>
          }
        />
      </FormItem>

      <FormActions>
        <Button onClick={handleSubmit(onFormSubmit)} type="secondary">
          {props.submitLabel}
        </Button>
      </FormActions>
    </form>
  );
};

export const Form = styled(FormInner)`
  display: flex;
  border-radius: 5px;
  flex-flow: column;
  padding: 30px;
  background: ${theme.color.primaryDark};
  color: white;

  .Checkbox {
    display: flex;
    flex-flow: row;
    input {
      margin-right: 10px;
      flex: 0;
    }
  }

  .MuiSvgIcon-root {
    font-size: 2.5rem;
  }

  input,
  textarea {
    border-radius: 5px;
    display: flex;
    margin-bottom: 10px;
    flex: 1;
    padding: 1.5rem;
    border: 2px solid ${theme.color.primaryDark};
    font-size: 1.6rem;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    ::placeholder {
      color: ${rgba(theme.color.primaryDark, 0.5)};
      opacity: 1; /* Firefox */
    }
  }
`;
