import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import styled from 'styled-components';
import { SectionTitle } from '../../../components/SectionTitle/SectionTitle';

export interface FormSectionHeaderProps {
  className?: string;
  title: string;
  subtitle: string;
  bold: string;
}

const FormSectionHeaderInner = (props: FormSectionHeaderProps) => {
  const { t } = useTranslation('translations');
  const { title, subtitle, bold } = props;

  return (
    <div className={props.className}>
      <SectionTitle textAlign="left">{title}</SectionTitle>
      <p>
        <i>{subtitle}</i>
      </p>
      <p>
        <h3>{bold}</h3>
      </p>
    </div>
  );
};

export const FormSectionHeader = styled(FormSectionHeaderInner)`
  margin-bottom: 40px;
`;
