import {
  EventSeo,
  StrapiEventProvider,
  TextSection,
  theme,
} from '@hromada-mono/hromada';
import { Box, Container } from '@material-ui/core';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaLaptop, FaRegCalendarAlt, FaUserFriends } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import Layout from '../../components/Shared/Layout/Layout';

const EventWrapper = styled.div`
  margin-top: 50px;

  img {
    width: 100%;
  }

  h1 {
    margin-bottom: 20px;
    font-size: 48px;

    @media (max-width: ${theme.breakpoints.md}) {
      font-size: 32px;
    }
  }
`;

const ImageWrapper = styled.div``;

export const query = graphql`
  query ($slug: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    strapiEvent(slug: { eq: $slug }) {
      title
      type
      id
      content
      dateTime

      cta {
        title
        text
        button {
          label
          link
        }
      }

      image {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
        }
      }
    }
  }
`;

const EventPage = (props) => {
  const {
    data: { strapiEvent },
  } = props;

  const { i18n, t } = useTranslation();

  const date = useMemo(() => {
    return new Date(strapiEvent.dateTime).toLocaleString('sk', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  }, [strapiEvent]);
  return (
    <Layout>
      <StrapiEventProvider event={strapiEvent}>
        <EventSeo />
        <ImageWrapper>
          <GatsbyImage
            style={{
              maxHeight: 500,
            }}
            alt="event hero"
            image={getImage(
              strapiEvent.image.localFile.childImageSharp.gatsbyImageData
            )}
          />
        </ImageWrapper>

        <Container>
          <EventWrapper>
            {/* Header */}
            <h1>{strapiEvent.title}</h1>
            {/* Info */}
            <Box
              paddingY={2}
              marginBottom={2}
              gridGap={10}
              fontWeight="500"
              fontSize="1.8rem"
              display="grid"
            >
              <Box display="flex" alignItems={'center'}>
                <FaRegCalendarAlt />
                <Box marginLeft={1.5}>{date}</Box>
              </Box>
              <Box display="flex" alignItems={'center'}>
                {strapiEvent.type === 'online' ? (
                  <FaLaptop />
                ) : (
                  <FaUserFriends />
                )}
                <Box marginLeft={1.5}>
                  {t(`events.type.${strapiEvent.type}`)}
                </Box>
              </Box>
            </Box>
            <ReactMarkdown
              transformImageUri={(uri) =>
                uri.startsWith('http')
                  ? uri
                  : `https://en.backoffice.branohromada.com${uri}`
              }
              children={strapiEvent.content}
            />
          </EventWrapper>
          {strapiEvent?.cta?.title && (
            <div className="cta">
              <TextSection
                title={strapiEvent?.cta?.title}
                description={strapiEvent?.cta?.text}
                button={strapiEvent?.cta?.button?.label}
                buttonUrl={strapiEvent?.cta?.button?.link}
              />
            </div>
          )}
        </Container>
      </StrapiEventProvider>
    </Layout>
  );
};

export default EventPage;
