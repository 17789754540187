import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import React from "react";
import styled from "styled-components";
import { Section } from "../../Shared/Layout/components/Section/Section";
import { muiTheme } from "../../Shared/Theme/Theme";
import { EbookDescription } from "./components/EbookDescription";
import { EbookImage } from "./components/EbookImage";

export interface EbookDescriptionSectionProps {
  className?: string;
}

const EbookDescriptionSectionInner = (props: EbookDescriptionSectionProps) => {
  return (
    <Section className={props.className}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={5}>
            <EbookDescription />
          </Grid>
          <Grid
            style={{
              position: "relative"
            }}
            item
            xs={12}
            md={7}
          >
            <EbookImage />
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

export const EbookDescriptionSection = styled(EbookDescriptionSectionInner)`
  /* overflow: hidden; */
  padding-top: 50px;
  background-color: ${props => props.theme.color.sectionBg};
  position: relative;
  height: 650px;

  ${muiTheme.breakpoints.down("sm")} {
    height: auto;
  }
`;
