import { Container, Grid } from "@material-ui/core";
import GatsbyImage from "gatsby-image";
import React from "react";
import { TextSection } from "../Layout/components/TextSection/TextSection";
import { useReferencesSectionData } from "./hooks/useReferencesSectionData";

export interface ReferencesSectionProps {
  className?: string;
}

export const ReferencesSection = (props: ReferencesSectionProps) => {
  const { className = "" } = props;
  const { title, description, references } = useReferencesSectionData();
  return (
    <section className={`${className}`}>
      <Container>
        <TextSection title={title} description={description} />
        <div
          className="reference-grid"
          style={{
            marginTop: "30px",
          }}
        >
          <Grid container spacing={6}>
            {references.map((reference) => (
              <Grid
                key={reference.title}
                item
                xs={4}
                md={3}
                lg={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  className="image"
                  style={{
                    width: "100%",
                  }}
                >
                  <GatsbyImage alt={reference.title} fluid={reference.image} />
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </Container>
    </section>
  );
};
