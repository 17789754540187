import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

export interface EbookDescriptionProps {
  className?: string;
}
//TODO: text nebol asi zatial
const EbookDescriptionInner = (props: EbookDescriptionProps) => {
  const { t } = useTranslation('translations');
  return (
    <div className={props.className}>
      <ReactMarkdown children={t('ebookPage.ebookDescription')} />
    </div>
  );
};

export const EbookDescription = styled(EbookDescriptionInner)``;
