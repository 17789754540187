import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { theme } from '../../../theme/theme';

export const ArticleContent = styled(Grid)`
  background-color: ${theme.color.sectionBg};
  margin-top: 10px;
  margin-bottom: 30px;
`;
export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const LeftSide = styled(Grid)``;
export const RightSide = styled(Grid)`
  padding: 30px;
  position: relative;

  ul {
    padding-inline-start: 20px;
  }

  li {
    color: ${theme.color.gray};
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;
