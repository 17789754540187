import React, { useMemo } from "react";
import styled from "styled-components";
import { languages } from "../../../../../../lib/consts";
import { LanguageLink } from "./LanguageLink";

export interface LanguageLinksProps {
  className?: string;
}

const Divider = styled.div`
  font-weight: 600;
  font-size: 2rem;

  margin: 0 5px;
`;

const renderLanguages = () => (
  <>
    {languages.map((lng, index) => (
      <div key={lng.label} style={{ display: "flex" }}>
        {index !== 0 && <Divider>/</Divider>}
        <LanguageLink {...lng}></LanguageLink>
      </div>
    ))}
  </>
);

const LanguageLinksInner = (props: LanguageLinksProps) => {
  return <div className={props.className}>{renderLanguages()}</div>;
};

export const LanguageLinks = styled(LanguageLinksInner)`
  display: flex;
  align-items: center;
`;
