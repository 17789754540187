import { Box, BoxProps } from '@material-ui/core';
import { Link } from 'gatsby';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';
import { theme } from '../../theme/theme';
import { Button } from '../Button/Primary/Button';

const ProfilePhotoWrapper = styled.div`
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
  height: 380px;
  overflow: hidden;

  img {
    height: 88% !important;
  }
`;
const LogoWrapper = styled.div`
  margin: 0 auto;
  max-width: 140px;
  width: 100%;
`;
const AdviceTitle = styled.h3`
  margin: 25px 0;
`;

const TelNumber = styled.a`
  font-weight: bold;
  font-size: 24px;
  color: ${theme.color.yellow};
`;

const Cta = styled(Button)`
  margin-top: 30px;
  margin-bottom: 20px;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;

export interface ContactMeSidebannerProps extends BoxProps {
  image: FluidObject;
  title: string;
  phoneNumber: string;
  openingHours: string;
  cta: {
    to: string;
    label: string;
  };
  bottomImage: FluidObject;
}

export const ContactMeSideBanner = (props: ContactMeSidebannerProps) => {
  const { image, title, phoneNumber, openingHours, cta, bottomImage } = props;
  return (
    <Box textAlign="center" height="100%" {...props}>
      <ProfilePhotoWrapper>
        <GatsbyImage fluid={image} />
      </ProfilePhotoWrapper>
      <AdviceTitle>{title}</AdviceTitle>
      <TelNumber href={`tel: ${phoneNumber}`}>{phoneNumber}</TelNumber>
      <p>{openingHours}</p>
      <Link to={cta.to}>
        <Cta type="secondary">{cta.label}</Cta>
      </Link>
      <LogoWrapper>
        <GatsbyImage fluid={bottomImage} />
      </LogoWrapper>
    </Box>
  );
};
