import { useTranslation } from "gatsby-plugin-react-i18next";
import { useCooperationImages } from "./useCooperationImages";
// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface ITherapyItem {
  title: string;
  description: string;
}
export const useTherapyItems = () => {
  const { t } = useTranslation("translations");
  const images = useCooperationImages();
  const therapyItems: ITherapyItem[] = t(
    "whyWithMePage.theProcessOfCooperation.cooperationSteps",
    {
      returnObjects: true
    }
  );

  return [
    {
      name: therapyItems[0].title,
      description: therapyItems[0].description,
      color: "#964D7D",
      image: images.firstContact
    },
    {
      name: therapyItems[1].title,
      description: therapyItems[1].description,
      color: "#8392C2",
      image: images.analysis
    },
    {
      name: therapyItems[2].title,
      description: therapyItems[2].description,
      color: "#E1C501",
      image: images.consultation
    },
    {
      name: therapyItems[3].title,
      description: therapyItems[3].description,
      color: "#A0A03E",
      image: images.follow
    },
    {
      name: therapyItems[4].title,
      description: therapyItems[4].description,
      color: "#F79600",
      image: images.end
    }
  ];
};
