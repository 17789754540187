import { Container } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { ArticleBanner, ArticleBannerProps, Section } from '../../components';

export interface LastArticlesProps {
  className?: string;
  articles: ArticleBannerProps[];
}

const LastArticlesInner = (props: LastArticlesProps) => {
  const { articles } = props;

  return (
    <Section className={props.className}>
      <Container>
        {articles.map((article) => (
          <ArticleBanner key={article.title} {...article} />
        ))}
      </Container>
    </Section>
  );
};

export const ArticleBannerListSection = styled(LastArticlesInner)``;
