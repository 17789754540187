import { TextSection } from '@hromada-mono/hromada';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import styled from 'styled-components';
import { routes } from '../../../lib/routes';

export interface OrientedOnSolutionsAndPraxisSectionProps {
  className?: string;
}

const OrientedOnSolutionsAndPraxisSectionInner = (
  props: OrientedOnSolutionsAndPraxisSectionProps
) => {
  const { t } = useTranslation('translations');

  return (
    <TextSection
      className={props.className}
      title={t('solutionsOrientedBanner.title2')}
      description={t('solutionsOrientedBanner.text')}
      buttonUrl={routes.services.to}
      button={t('solutionsOrientedBanner.button')}
    />
  );
};

export const OrientedOnSolutionsAndPraxisSection = styled(
  OrientedOnSolutionsAndPraxisSectionInner
)``;
