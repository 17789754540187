import { Box } from '@material-ui/core';
import React from 'react';
import {
  Section,
  SectionLinks,
  TextSection,
  TextSectionProps,
} from '../../components';
import {
  ServiceSection,
  ServiceSectionProps,
} from '../ServiceSection/ServiceSection';

export interface ServicesSectionProps {
  title?: TextSectionProps;
  services: ServiceSectionProps[];
  showLinks?: boolean;
}

export const ServicesSection = (props: ServicesSectionProps) => {
  const { title, services, showLinks = true } = props;
  return (
    <Section>
      {title && (
        <TextSection title={title.title} description={title.description} />
      )}
      {showLinks && (
        <SectionLinks
          links={services.map((service) => ({
            buttonTitle: service.title,
            shortText: service.shortText,
            buttonUrl: `#${service.title}`,
          }))}
        />
      )}
      <Box>
        {services.map((service) => (
          <ServiceSection key={service.title} {...service} />
        ))}
      </Box>
    </Section>
  );
};
