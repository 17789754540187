import Hidden from "@material-ui/core/Hidden";
import GatsbyImage from "gatsby-image";
import React from "react";
import styled from "styled-components";
import { muiTheme } from "../../../Shared/Theme/Theme";
import { useEbookImage } from "../hooks/useEbookImage";

export interface EbookImageProps {
  className?: string;
}

const EbookImageInner = (props: EbookImageProps) => {
  const image = useEbookImage();
  return (
    <div className={props.className}>
      <GatsbyImage fluid={image} />
    </div>
  );
};

export const EbookImage = styled(EbookImageInner)`
  position: absolute;
  top: -50px;
  left: 16px;
  width: 1100px;
  max-height: 720px;
  overflow: hidden;

  ${muiTheme.breakpoints.down("sm")} {
    position: relative;
    width: 100%;
    left: unset;
    bottom: 0;
    top: 0;
  }
`;
