import { useStaticQuery, graphql } from "gatsby";

export const useServiceCategoryImages = (): { [key: string]: any } => {
  const { coaching, therapy, leadership, workshops } = useStaticQuery(graphql`
    query ServicesImages {
      coaching: file(relativePath: { eq: "Services/Coaching/thumb.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      therapy: file(relativePath: { eq: "Services/Therapy/thumb.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      leadership: file(relativePath: { eq: "Services/Leadership/thumb.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      workshops: file(relativePath: { eq: "Services/Workshops/thumb.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return { coaching, therapy, leadership, workshops };
};
