import { Box, BoxProps } from '@material-ui/core';
import { Link } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaLaptop, FaRegCalendarAlt, FaUserFriends } from 'react-icons/fa';
import RemoveMarkdown from 'remove-markdown';
import styled from 'styled-components';
import { ViewMoreButton } from '../../../components';
import { StrapiEvent } from '../../../models/Event/event';

export interface EventCardProps extends StrapiEvent.Event {
  link: {
    to: string;
    label: string;
  };
}

export const EventCard = (props: EventCardProps) => {
  const { title, content, image, link, type, dateTime } = props;
  const { t, i18n } = useTranslation();

  const date = useMemo(() => {
    return new Date(dateTime).toLocaleString(i18n.language, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  }, [dateTime]);

  return (
    <Box display="flex" flex="1 1 auto">
      <CustomLink to={link.to}>
        <TopImageWrapper>
          <GatsbyImage
            image={getImage(image.localFile.childImageSharp.gatsbyImageData)}
          />
        </TopImageWrapper>

        <Box
          paddingX={2.5}
          paddingY={2}
          color="white"
          bgcolor="primary.main"
          display={'flex'}
          alignItems="center"
          justifyContent={'space-between'}
          fontWeight="500"
          fontSize="1.6rem"
        >
          <Box display="flex" alignItems={'center'}>
            <FaRegCalendarAlt />
            <Box marginLeft={1.5}>{date}</Box>
          </Box>
          <Box display="flex" alignItems={'center'}>
            {type === 'online' ? <FaLaptop /> : <FaUserFriends />}
            <Box marginLeft={1.5}>{t(`events.type.${type}`)}</Box>
          </Box>
        </Box>

        <Bottom>
          <ArticleTitle>{title}</ArticleTitle>
          <ArticleDescription>
            {RemoveMarkdown(content).slice(0, 200)}...
          </ArticleDescription>
          <ViewMoreButton to={link.to}>{link.label}</ViewMoreButton>
        </Bottom>
      </CustomLink>
    </Box>
  );
};

const TopImageWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  min-height: 230px;
  display: flex;
`;

const CustomLink = styled(Link)`
  height: 100%;
  transform: scale(1);
  transition-duration: 0.3s;
  flex: 1 1 auto;
  transition-property: transform;
  transition-timing-function: ease-in-out;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.color.sectionBg};

  &:hover {
    transform: scale(1.01);
    transition-duration: 230ms;
    box-shadow: 0 6px 6px -10px rgba(0, 0, 0, 0.5);
  }
`;

const ArticleTitle = styled.h3`
  margin-bottom: 30px;
`;

const ArticleDescription = styled.p`
  height: 100%;
  margin-bottom: 25px;
`;

const Bottom = styled.div`
  padding: 30px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export interface ArticleCardProps extends BoxProps {
  className?: string;
  title: string;
  description: string;
  image: FluidObject;
  link: {
    label: string;
    href: string;
  };
}

export const ArticleCard = (props: ArticleCardProps) => {};
