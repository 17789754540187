import { useStaticQuery, graphql } from "gatsby";

export const useCooperationImages = () => {
  const {
    firstContact,
    analysis,
    consultation,
    follow,
    end
  } = useStaticQuery(graphql`
    query CooperationImages {
      firstContact: file(
        relativePath: { eq: "WhyWithMe/Cooperation/first_contact.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      analysis: file(
        relativePath: { eq: "WhyWithMe/Cooperation/analysis.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      consultation: file(
        relativePath: { eq: "WhyWithMe/Cooperation/consultation.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      follow: file(relativePath: { eq: "WhyWithMe/Cooperation/follow.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      end: file(relativePath: { eq: "WhyWithMe/Cooperation/end.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return {
    firstContact: firstContact.childImageSharp.fluid,
    analysis: analysis.childImageSharp.fluid,
    consultation: consultation.childImageSharp.fluid,
    follow: follow.childImageSharp.fluid,
    end: end.childImageSharp.fluid
  };
};
