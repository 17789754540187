import { theme } from '@hromada-mono/hromada';
import { Box, Grid } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import {
  AccordionElement,
  AccordionElementProps,
} from '../../components/AccordionElement/AccordionElement';
import { ViewMoreButton } from '../../components/Button/ViewMore/ViewMoreButton';
import { Section } from '../../components/Section/Section';

export interface PlainAccordionSectionProps {
  title: string;
  content: string;
  accordion: AccordionElementProps;
  button: {
    link: string;
    label: string;
  };
}

export const PlainAccordionSection = (props: PlainAccordionSectionProps) => {
  const { title, content, accordion, button } = props;
  return (
    <Section {...props}>
      <Grid container spacing={5}>
        <Grid
          item
          sm={6}
          xs={12}
          container
          alignContent="flex-start"
          justify="space-between"
        >
          <Title>{title}</Title>
          <Box color={theme.color.gray}>{content}</Box>
          <ViewMoreButton
            style={{
              marginTop: 20,
            }}
            to={button.link}
          >
            {button.label}
          </ViewMoreButton>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Box
            marginTop={{
              xs: 0,
              sm: '30px',
            }}
          >
            <AccordionElement {...accordion} />
          </Box>
        </Grid>
      </Grid>
    </Section>
  );
};

const Title = styled.h2`
  margin-bottom: 20px;
`;
