import React from "react";
import styled from "styled-components";

export interface ColorfulParagraphProps {
  className?: string;
  text: string;
  color: string;
}

const ColorfulParagraphInner = (props: ColorfulParagraphProps) => {
  const { text } = props;
  return <span className={props.className}>{text}</span>;
};

export const ColorfulParagraph = styled(ColorfulParagraphInner)`
  color: ${props => props.color};
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`;
