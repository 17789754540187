import {
  ArticleBannerListSection,
  SEO,
  TopHeader,
} from '@hromada-mono/hromada';
import { graphql } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrientedOnSolutionsAndPraxisSection } from '../../components/Blog/components/OrientedOnSolutionsAndPraxisSection';
import { useNLastBlogPosts } from '../../components/Shared/hooks/useNLastBlogPosts';
import Layout from '../../components/Shared/Layout/Layout';
import { generatePostPath } from '../../lib/utils';

const AllBlogsPage = () => {
  const { t } = useTranslation();
  const articles = useNLastBlogPosts(-1);

  return (
    <Layout>
      <TopHeader title="Blog list" />
      <SEO title="Blog" />
      <ArticleBannerListSection
        articles={articles.map((article) => ({
          ...article,
          link: generatePostPath(article.title),
          image: article.thumbnail.localFile.childImageSharp.fluid,
          viewMore: {
            link: generatePostPath(article.title),
            label: t('buttons.viewMore'),
          },
        }))}
      />
      <OrientedOnSolutionsAndPraxisSection />
    </Layout>
  );
};
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default AllBlogsPage;
