import { Box, BoxProps } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { TelNumber } from '../../sections/FormSection/components/styled';

export interface ContactBannerItemProps extends BoxProps {
  link: {
    label: string;
    href: string;
  };
  subtitle: string;
  icon: ReactNode;
}

export const ContactBannerItem = (props: ContactBannerItemProps) => {
  const { link, subtitle, icon } = props;
  return (
    <Box {...props} display="flex">
      <Box display="flex">{icon}</Box>
      <Box display="flex" flexDirection="column" marginLeft="10px">
        <TelNumber href="tel:+421 908 112 639"></TelNumber>
        <Box
          component="a"
          fontWeight="bold"
          fontSize="2.4rem"
          marginBottom="10px"
          display="flex"
        >
          {link.label}
        </Box>
        <p>{subtitle}</p>
      </Box>
    </Box>
  );
};
