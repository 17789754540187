import {
  ButtonAndShortText,
  ReferencesSection,
  TextSection,
  TopHeader,
} from '@hromada-mono/hromada';
import { Box } from '@material-ui/core';
import { graphql } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BlogSectionControl } from '../../../components/Home/BlogSectionControl';
import { FormSectionControl } from '../../../components/Home/FormSectionControl';
import Layout from '../../../components/Shared/Layout/Layout';
import { useReferencesSectionData } from '../../../components/Shared/ReferencesSection/hooks/useReferencesSectionData';

export interface LeadershipAndSalesSkillsProps {
  className?: string;
  data: any;
}

const LeadershipAndSalesSkills = (props: LeadershipAndSalesSkillsProps) => {
  const { data } = props;
  const { t } = useTranslation();
  const referencesSectionData = useReferencesSectionData();

  return (
    <Layout>
      <TopHeader title="Servicios / Habilidades de liderazgo y ventas" />
      <TextSection
        title={t('leadershipAndSalesSkillsPage.getOutSection.title')}
        description={t(
          'leadershipAndSalesSkillsPage.getOutSection.description'
        )}
      />

      <Box
        marginTop="30px"
        display="flex"
        justifyContent="center"
        flexDirection={{
          xs: 'column',
          lg: 'row',
        }}
      >
        {data.data.buttons?.map((button: any, index) => (
          <Box
            key={index}
            marginX={{
              xs: '0px',
              md: '10px',
            }}
          >
            <ButtonAndShortText
              buttonTitle={button.label}
              buttonUrl={button.link}
            />
          </Box>
        ))}
      </Box>

      <ReferencesSection {...referencesSectionData} />

      <BlogSectionControl />
      <FormSectionControl />
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    data: strapiLeadershipAndSalesSkills {
      buttons {
        label
        link
      }
    }
  }
`;

export default LeadershipAndSalesSkills;
