import { useStaticQuery, graphql } from "gatsby";

export interface FaqSmall {
  title: string;
  content: string;
}

export const useWhyThisJobBoxSections = (): FaqSmall[] => {
  const {
    strapiHome: { letsStart },
  } = useStaticQuery(graphql`
    query useWhyThisJobBoxSections {
      strapiHome {
        letsStart {
          title
          content
        }
      }
    }
  `);

  return letsStart;
};
