import { Link } from "gatsby";
import React, { ReactNode } from "react";
import styled from "styled-components";

export interface ViewMoreButtonProps {
  className?: string;
  children?: ReactNode;
}

export const ViewMoreButton = styled(Link)`
  color: ${props => props.theme.color.gray};
  text-align: right;
  font-size: 18px;
  transition: all 0.3s linear;
  margin-top: 20px;

  &:hover {
    opacity: 0.6;
  }
`;
