import { graphql, useStaticQuery } from 'gatsby';

export interface FreeVideo {
  title: string;
  text: string;
  url: string;
}

export const useFreeVideos = (): FreeVideo[] => {
  const {
    strapiFreeResources: { videos },
  } = useStaticQuery(graphql`
    query useFreeVideos {
      strapiFreeResources {
        videos {
          title
          url: videoUrl
          text
        }
      }
    }
  `);

  return videos;
};
