import { Container } from '@material-ui/core';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';
import { OrganizationReference } from '../../components/OrganizationReference/OrganizationReference';
import { ImageWrapper } from '../../components/SectionHeaderWithBgImage/SectionHeaderWithBgImage';
import { SectionTitle } from '../../components/SectionTitle/SectionTitle';

export interface OrganizationReferenceSectionProps {
  image: FluidObject;
  title: string;
  subtitle: string;
  references: {
    title: string;
    image: any;
    content: string;
  }[];
}

export const OrganisationReferenceSection = (
  props: OrganizationReferenceSectionProps
) => {
  const { title, image, subtitle, references } = props;
  return (
    <ReferencesSection key={title} id={title}>
      <header>
        <ImageWrapper>
          <GatsbyImage className="bannerImage" fluid={image} />
        </ImageWrapper>
        <Container>
          <SectionTitle textAlign="left" marginTop="30px">
            {title}
          </SectionTitle>
          <ReferencesSectionDescription>
            {subtitle}
          </ReferencesSectionDescription>
        </Container>
      </header>
      <div className="references">
        {references.map((reference, index) => (
          <OrganizationReference
            key={reference.title}
            background={index % 2 !== 0}
            title={reference.title}
            image={reference.image.localFile.childImageSharp.fluid}
            content={reference.content}
          />
        ))}
      </div>
    </ReferencesSection>
  );
};

export const ReferencesSectionDescription = styled.div`
  color: ${(props) => props.theme.color.gray};
`;

const ReferencesSection = styled.section`
  margin-top: 60px;

  .bannerImage {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;
