import {
  ImageAccordionSection,
  ReferencesSection,
  ServicesSection,
} from '@hromada-mono/hromada';
import { BoxProps } from '@material-ui/core';
import { graphql } from 'gatsby';
import React from 'react';
import { BlogSectionControl } from '../../../components/Home/BlogSectionControl';
import { FormSectionControl } from '../../../components/Home/FormSectionControl';
import { TextSection } from '../../../components/Shared/Layout/components/TextSection/TextSection';
import { TopHeader } from '../../../components/Shared/Layout/components/TopHeader/TopHeader';
import Layout from '../../../components/Shared/Layout/Layout';
import { useReferencesSectionData } from '../../../components/Shared/ReferencesSection/hooks/useReferencesSectionData';

export interface LIDERAZGOPageProps extends BoxProps {
  data: any;
}

export const query = graphql`
  query leadershipPageQuery($language: String!) {
    strapiLeadership {
      title {
        title
        description
      }
      accordionSection {
        accordion {
          title: label
          content
        }
        description
        title
        image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      sectionsTitle {
        title
        description
      }
      services {
        title
        text
        shortText
        reference {
          title
          text
          image {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const LIDERAZGOPage = (props: LIDERAZGOPageProps) => {
  const {
    className = '',
    data: { strapiLeadership: data },
  } = props;

  const referencesSectionData = useReferencesSectionData();

  return (
    <Layout>
      <TopHeader title="SERVICIOS / HABILIDADES DE LIDERAZGO Y VENTAS / LIDERAZGO" />
      <TextSection {...data?.title} />
      <ImageAccordionSection
        title={data?.accordionSection?.title}
        description={data?.accordionSection?.description}
        items={data?.accordionSection?.accordion}
        image={data?.accordionSection?.image?.localFile?.childImageSharp?.fluid}
      />

      <ReferencesSection {...referencesSectionData} />

      <ServicesSection
        title={data?.sectionsTitle}
        services={data.services.map((service) => ({
          title: service.title,
          content: service.text,
          shortText: service.shortText,
          image: service.image.localFile.childImageSharp.fluid,
          reference: {
            image: service.reference.image.localFile.childImageSharp.fluid,
            title: service.reference.title,
            content: service.reference.text,
            button: {
              label: service?.reference?.button?.label || 'Pide una cita',
              link: service?.reference?.button?.link || '/contacto',
            },
          },
        }))}
      />

      <BlogSectionControl />
      <FormSectionControl />
    </Layout>
  );
};

export default LIDERAZGOPage;
