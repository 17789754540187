import { Container, Hidden } from "@material-ui/core";
import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { routes } from "../../../../../lib/routes";
import { muiTheme } from "../../../Theme/Theme";
import { LanguageLinks } from "./components/LanguageLinks";
import { LogoLink } from "./components/LogoLink";
import { MobileMenu } from "./components/MobileMenu";

export type HeaderProps = React.HTMLAttributes<HTMLElement>;

const NavLink = styled(Link)`
  font-size: 1.6rem;
  text-align: center;
  font-weight: 600;
  transition: all 230ms ease-in-out;

  color: ${(props) => props.theme.color.primaryDark};
  padding: 0 12px;

  ${muiTheme.breakpoints.up("md")} {
    padding: 0 20px;
  }

  &.active,
  &:hover {
    color: ${(props) => props.theme.color.primary};
  }
`;

const HeaderInner: React.FC<HeaderProps> = (props: HeaderProps) => {
  return (
    <header className={props.className}>
      <Container>
        <nav>
          <LogoLink />
          <Hidden smDown>
            <div className="nav-items">
              {Object.values(routes)
                .filter((route) => !route.hideInMenu)
                .map((route) => (
                  <NavLink
                    key={route.to}
                    activeClassName="active"
                    to={route.to}
                  >
                    {route.label}
                  </NavLink>
                ))}
            </div>
          </Hidden>
          <Hidden smDown>
            <LanguageLinks />
          </Hidden>
          <Hidden mdUp>
            <MobileMenu />
          </Hidden>
        </nav>
      </Container>
    </header>
  );
};

export const Header = styled(HeaderInner)<HeaderProps>`
  display: flex;
  height: 90px;
  background: ${(props) => props.theme.color.white};
  align-items: center;
  position: relative;

  nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  ${muiTheme.breakpoints.down("sm")} {
    nav {
      justify-content: center;
    }
  }}

  .nav-items {
    display: flex;
    align-items: center;
  }
`;
