import { Box, Container, Hidden } from '@material-ui/core';
import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { FluidObject } from 'gatsby-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from '../../components/Button/Primary/Button';
import Bottom from './assets/bottom.svg';
import { HeroTile } from './components/HeroTile.';

const HeroBottomSvg = styled.img`
  width: 100%;
  bottom: 0;
  position: absolute;
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  min-height: 800px;
  position: relative;
`;

const CaptainText = styled.h2`
  font-family: 'Grand Hotel', sans-serif;
  font-size: 56px;
  font-weight: 400;

  position: absolute;
  right: 0;
  bottom: -100px;
`;

const CaptainTextContainer = styled(Container)`
  position: relative;
`;

export interface HeroSectionProps {
  className?: string;
  title: string;
  subtitle: string;
  cta: {
    label: string;
    url: string;
  };
  fancy: string;
  image: FluidObject;
}

const HeroSectionInner = (props: HeroSectionProps) => {
  const { image, title, subtitle, fancy, cta } = props;

  return (
    <Box
      maxWidth="100vw"
      overflow="hidden"
      component="section"
      position="relative"
      className={props.className}
    >
      <StyledBackgroundImage fluid={image}>
        <div className="content">
          <HeroTile title={title} subtitle={subtitle} />
          <Hidden mdDown>
            <CaptainTextContainer>
              <Box
                fontFamily="'Grand Hotel', sans-serif"
                fontSize="56px"
                fontWeight="400"
                position="absolute"
                right="0"
                bottom="-100px"
              >
                {fancy}
              </Box>
            </CaptainTextContainer>
          </Hidden>
          <Link to={cta.url}>
            <Button type="primary">{cta.label}</Button>
          </Link>
        </div>
        <HeroBottomSvg src={Bottom} alt="" />
      </StyledBackgroundImage>
    </Box>
  );
};

export const HeroSection = styled(HeroSectionInner)`
  max-width: 100vw;
  overflow-x: hidden;

  .content {
    position: absolute;
    top: 50%;
    display: flex;
    flex-flow: column;
    width: 100%;
    align-items: center;

    ${Button} {
      margin-top: 20px;
    }
  }
`;
