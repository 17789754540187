import Grid from "@material-ui/core/Grid";
import GatsbyImage from "gatsby-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import { SectionTitle } from "../../../../../../SectionTitle/SectionTitle";
import { muiTheme } from "../../../../../../Theme/Theme";
import { useFooterImages } from "../../../hooks/useFooterImages";
import { SmallTitle } from "../../SmallTitle";
import { ContactItems } from "./ContactItems";
import { LanguageSwitcher } from "./LanguageSwitcher";

export interface TopLeftGridProps {
  className?: string;
}

const TopLeftGridInner = (props: TopLeftGridProps) => {
  const footerImages = useFooterImages();
  const { t } = useTranslation("translations");
  return (
    <Grid className={props.className} item md={6} sm={12}>
      <FooterMotto color="white">{t("hero.motto")}</FooterMotto>
      <SmallTitle> {t("footer.IamPartOf")}</SmallTitle>
      <LogoWrapper>
        <GatsbyImage fixed={footerImages.solutionsurfers} />
      </LogoWrapper>
      <LanguageSwitcher />
      <ContactItems />
    </Grid>
  );
};

const FooterMotto = styled.h2`
  font-size: 56px;
  font-family: "Grand Hotel", sans-serif;
  font-weight: 400;
  text-transform: none;
  color: white;
`;
const LogoWrapper = styled.div``;

export const TopLeftGrid = styled(TopLeftGridInner)`
  ${muiTheme.breakpoints.down("sm")} {
    order: 2;
  }
`;
