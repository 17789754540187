import { graphql, useStaticQuery } from 'gatsby';
import { FluidObject } from 'gatsby-image';

export interface ReferencesSectionData {
  title: string;
  description: string;
  references: {
    title: string;
    image: FluidObject;
  }[];
}

export const useReferencesSectionData = (): ReferencesSectionData => {
  const { strapiGlobal, strapiReferences } = useStaticQuery(graphql`
    query useReferencesSectionData {
      strapiGlobal {
        referencesSection {
          title
          description
        }
      }

      strapiReferences {
        sections {
          references {
            title
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 300, quality: 60) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const [businessReferencesSection] = strapiReferences?.sections;

  return {
    title: strapiGlobal?.referencesSection?.title,
    description: strapiGlobal?.referencesSection?.description,
    references: businessReferencesSection?.references?.map(
      (reference: any) => ({
        title: reference?.title,
        image: reference?.image?.localFile.childImageSharp?.fluid,
      })
    ),
  };
};
