import { Box, Container } from '@material-ui/core';
import { Link } from 'gatsby';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';
import { Button } from '../Button/Primary/Button';
import { Section } from '../Section/Section';
import { SectionTitle } from '../SectionTitle/SectionTitle';

const TextSectionDescription = styled.p`
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column;
`;

export interface TextSectionProps {
  className?: string;
  title: string;
  description: string;
  button?: string;
  buttonUrl?: string;
  image?: FluidObject;

  darkBg?: boolean;
}

const TextSectionInner = (props: TextSectionProps) => {
  const { title, description, button, buttonUrl, image } = props;
  return (
    <Section darkBg={props.darkBg} className={props.className}>
      <Container>
        <Wrapper>
          {image && (
            <Box display="flex" justifyContent="center">
              <Box
                minWidth={{
                  xs: '180px',
                  md: '280px',
                }}
                marginBottom="30px"
              >
                <GatsbyImage fluid={image} />
              </Box>
            </Box>
          )}
          <SectionTitle>{title}</SectionTitle>
          <TextSectionDescription>{description}</TextSectionDescription>
          {button && (
            <Link className="button-link" to={buttonUrl || '/'}>
              <Button type="primary">{button}</Button>
            </Link>
          )}
        </Wrapper>
      </Container>
    </Section>
  );
};

export const TextSection = styled(TextSectionInner)`
  ${Button} {
    margin-top: 30px;
  }

  .button-link {
    display: flex;
    justify-content: center;
  }
`;
