import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TextSection } from "../../Shared/Layout/components/TextSection/TextSection";

export interface EbookDisclaimerProps {
  className?: string;
}

const EbookDisclaimerInner = (props: EbookDisclaimerProps) => {
  const { t } = useTranslation("translations");
  return (
    <TextSection
      className={props.className}
      title={t("ebookPage.disclaimer.title")}
      description={t("ebookPage.disclaimer.text")}
    />
  );
};

export const EbookDisclaimer = styled(EbookDisclaimerInner)``;
