import Container from "@material-ui/core/Container";
import React from "react";
import styled from "styled-components";
import { Section } from "../Layout/components/Section/Section";
import { EmailComponent } from "./components/EmailComponent";

export interface EmailComponentSectionProps {
  className?: string;
}

const EmailComponentSectionInner = (props: EmailComponentProps) => {
  return (
    <Section className={props.className}>
      <Container>
        <EmailComponent />
      </Container>
    </Section>
  );
};

export const EmailComponentSection = styled(EmailComponentSectionInner)``;
