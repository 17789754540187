import BackgroundImage from 'gatsby-background-image';
import { FluidObject } from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';
import { SectionTitle } from '../SectionTitle/SectionTitle';

export interface SectionHeaderWithBgImageProps {
  className?: string;
  image: FluidObject;
  title: string;
}

const SectionHeaderWithBgImageInner = (
  props: SectionHeaderWithBgImageProps
) => {
  const { image, title } = props;
  return (
    <section className={props.className}>
      <ImageWrapper>
        <BackgroundImage fluid={image} className="backgroundImage">
          <div className="customized-container">
            {/* <SectionTitle>{title}</SectionTitle> */}
          </div>
        </BackgroundImage>
      </ImageWrapper>
    </section>
  );
};

export const SectionHeaderWithBgImage = styled(SectionHeaderWithBgImageInner)`
  .backgroundImage {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .customized-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${SectionTitle} {
    text-transform: initial;
    font-size: 55px;
    color: ${(props) => props.theme.color.primaryDark};
    margin-bottom: 0;
  }
`;

export const ImageWrapper = styled.div`
  height: 285px;
  width: 100%;

  @media (max-width: 768px) {
    height: 100px;
  }
`;
