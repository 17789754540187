import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import { Socials } from "../../../../../Socials/Socials";
import { muiTheme } from "../../../../../Theme/Theme";

export interface BottomFooterProps {
  className?: string;
}

const BottomFooterInner = (props: BottomFooterProps) => {
  const { t } = useTranslation("translations");
  return (
    <div className={props.className}>
      <Container className="wrapper">
        <p>
          {t("contact.email")} {t("footer.allRightsReserved")}
        </p>
        <Socials />
      </Container>
    </div>
  );
};

export const BottomFooter = styled(BottomFooterInner)`
  background-color: ${props => props.theme.color.sectionBg};
  padding-top: 28px;
  padding-bottom: 35px;

  .wrapper {
    display: flex;
    justify-content: space-between;

    ${muiTheme.breakpoints.down("sm")} {
      flex-flow: column;
      align-items: center;

      ${Socials} {
        margin-top: 20px;
      }
    }
  }

  p {
    margin: 0;
    display: flex;
    align-items: center;
  }
`;
