import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import styled from 'styled-components';
import { Section } from '../Section/Section';
import { SectionTitle } from '../SectionTitle/SectionTitle';
import { StepItem, StepItemProps } from './components/StepItem';

export interface ProcessOfCooperationSectionProps {
  className?: string;
  title: string;
  steps: StepItemProps[];
}

const ProcessOfCooperationSectionInner = (
  props: ProcessOfCooperationSectionProps
) => {
  const { steps, title } = props;

  return (
    <Section darkBg className={props.className}>
      <Container>
        <SectionTitle textAlign="center">{title}</SectionTitle>
        <Grid container direction="column">
          {steps.map((step, index) => (
            <StepItem key={index} {...step} number={index + 1} />
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export const Steps = styled(ProcessOfCooperationSectionInner)``;
