import { JsonLd } from 'gatsby-plugin-next-seo';
import React, { useMemo } from 'react';
import { Event, WithContext } from 'schema-dts';
import { useStrapiEvent } from '../Events/providers/StrapiEventProvider';
import { SEO } from './SEO';

export interface EventSeoProps {}

export const EventSeo = (props: EventSeoProps) => {
  const eventData = useStrapiEvent();

  const eventSchema = useMemo((): WithContext<Event> => {
    return {
      '@context': 'https://schema.org',
      '@type': 'Event',
      name: eventData.title,
      startDate: eventData.dateTime,
    };
  }, []);

  return (
    <>
      <SEO title={eventData.title} />
      <JsonLd
        json={{
          ...eventSchema,
        }}
      />
    </>
  );
};
