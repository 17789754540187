import { MostRequestedServiceProps } from '@hromada-mono/hromada';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { routes } from '../../../lib/routes';
import { useRequestedServicesImages } from '../HeroSection/hooks/useRequestedServicesImages';

export const useMostRequestedServices = () => {
  const { t } = useTranslation();

  const images = useRequestedServicesImages();
  const mostRequestedServices: MostRequestedServiceProps[] = useMemo(
    () => [
      {
        ...t('services.coaching', { returnObjects: true }),
        image: images.chat,
        link: routes.servicesFolder.coaching.to,
      },
      {
        ...t('services.therapy', { returnObjects: true }),
        image: images.person,
        link: routes.servicesFolder.therapy.to,
      },
      {
        ...t('services.leadership', { returnObjects: true }),
        image: images.rocket,
        link: routes.servicesFolder.leadership.to,
      },
    ],
    []
  );

  return mostRequestedServices;
};
