import {
  AccordionBox,
  AccordionBoxProps,
  Button,
  ContactMeSideBanner,
  ContactMeSidebannerProps,
  Link,
  Section,
} from '@hromada-mono/hromada';
import { Box, BoxProps, Container, Grid } from '@material-ui/core';
import React from 'react';
import { SectionTitle } from '../../components/SectionTitle/SectionTitle';
import { Button as ButtonModel } from '../../models/Button/button.model';

export interface LetsStartSectionProps extends BoxProps {
  title: string;
  sideBanner: ContactMeSidebannerProps;
  accordionBox: AccordionBoxProps;
  buttons: ButtonModel[];
}

export const LetsStartSection = (props: LetsStartSectionProps) => {
  const { title, sideBanner, accordionBox, buttons } = props;

  return (
    <Section {...props}>
      <Container>
        <SectionTitle textAlign="left">{title}</SectionTitle>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <ContactMeSideBanner {...sideBanner} />
          </Grid>
          <Grid item xs={12} md={9}>
            <AccordionBox padding="30px" {...accordionBox} />

            <Box padding="30px">
              <Grid container spacing={4} justify="center">
                {buttons.map((button) => (
                  <Grid container item sm={5} xs={12} justify="center">
                    <Link to={button.to}>
                      <Button type="primary">{button.label}</Button>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};
