import Container from "@material-ui/core/Container";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import { Section } from "../../Shared/Layout/components/Section/Section";

export interface AppreciationSectionProps {
  className?: string;
}

const AppreciationSectionInner = (props: AppreciationSectionProps) => {
  const { t } = useTranslation("translations");
  return (
    <Section className={props.className}>
      <Container>
        <TextWrapper>
          <p>{t("whyWithMePage.appreciation.text")}</p>
        </TextWrapper>
      </Container>
    </Section>
  );
};

export const AppreciationSection = styled(AppreciationSectionInner)`
  background-color: ${props => props.theme.color.sectionBg};
`;

const TextWrapper = styled.div`
  text-align: center;
  padding: 35px 0;
  p {
    font-weight: bold;
    font-size: 25px;
    color: ${props => props.theme.color.primaryDark};
    margin-bottom: 35px;
  }
`;
