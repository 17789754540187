import React from "react";
import styled from "styled-components";
import { muiTheme } from "../Theme/Theme";
import { useTranslation } from "gatsby-plugin-react-i18next";

export interface CaptainTitleProps {
  className?: string;
  color: "dark" | "white";
}

const CaptainTitleInner = (props: CaptainTitleProps) => {
  const { t } = useTranslation("translations");
  return <p className={props.className}>{t("hero.motto")}</p>;
};

export const CaptainTitle = styled(CaptainTitleInner)`
  font-family: ${props => props.theme.fonts.secondary};
  color: ${props =>
    props.color === "dark"
      ? props.theme.color.primaryDark
      : props.theme.color.white};

  font-size: 62px;
  text-align: center;

  ${muiTheme.breakpoints.down("xs")} {
    font-size: 31px;
  }
`;
