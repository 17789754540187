import {
  HeroSection,
  MostRequestedServices,
  TopHeader,
} from '@hromada-mono/hromada';
import { graphql } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NotFoundMainSection } from '../components/404/NotFoundMainSection';
import { useHeroImage } from '../components/Shared/HeroSection/hooks/useHeroImage';
import { useMostRequestedServices } from '../components/Shared/hooks/useMostRequestedServices';
import Layout from '../components/Shared/Layout/Layout';
import { routes } from '../lib/routes';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const NotFoundPage = () => {
  const image = useHeroImage();
  const { t } = useTranslation();
  const mostRequestedServices = useMostRequestedServices();

  return (
    <Layout>
      <TopHeader title="Ooops :(" />
      <NotFoundMainSection />
      <HeroSection
        image={image}
        title={t('hero.title.big')}
        fancy={t('hero.motto')}
        subtitle={t('hero.title.small')}
        cta={{
          url: routes.whyWithMe.to,
          label: t('hero.callToActionButton'),
        }}
      />
      <MostRequestedServices
        title={t('mostRequestedServicesBanner.title')}
        services={mostRequestedServices}
      />
      {/* <BottomTextSection /> */}
    </Layout>
  );
};

export default NotFoundPage;
