import { useTranslation } from "gatsby-plugin-react-i18next";

export const useParagraphs = () => {
  const { t } = useTranslation("translations");
  const paragraphTranslations: string[] = t(
    "whyWithMePage.appreciation.paragraphs",
    {
      returnObjects: true
    }
  );
  return [
    {
      paragraph: paragraphTranslations[0],
      color: "#D3C71D",
      justifyContent: "flex-start"
    },
    {
      paragraph: paragraphTranslations[1],
      color: "#964D7D",
      justifyContent: "flex-end"
    },
    {
      paragraph: paragraphTranslations[2],
      color: "#F6A837",
      justifyContent: "flex-end"
    },
    {
      paragraph: paragraphTranslations[3],
      color: "#33354A",
      justifyContent: "center"
    },
    {
      paragraph: paragraphTranslations[4],
      color: "#DD2E66",
      justifyContent: "center"
    },
    {
      paragraph: paragraphTranslations[5],
      color: "#9EA347",
      justifyContent: "flex-start"
    }
  ];
};
