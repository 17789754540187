import { Link as GatsbyLink } from 'gatsby';
import React, { ReactNode, useMemo } from 'react';

export interface LinkWrapperProps {
  to?: string;
  children: ReactNode | ReactNode[];
}

export const Link: React.FC<LinkWrapperProps> = ({ to, children }) => {
  const isExternal = useMemo(() => {
    return to && to.startsWith('http');
  }, [to]);

  if (!to) {
    return <>{children}</>;
  }

  if (isExternal) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }
  return <GatsbyLink to={to}>{children}</GatsbyLink>;
};
