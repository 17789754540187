import {
  ImageAccordionSection,
  SEO,
  ServicesSection,
  TextSection,
} from '@hromada-mono/hromada';
import { graphql } from 'gatsby';
import React from 'react';
import { BlogSectionControl } from '../../components/Home/BlogSectionControl';
import { TopHeader } from '../../components/Shared/Layout/components/TopHeader/TopHeader';
import Layout from '../../components/Shared/Layout/Layout';

export interface TherapyPageProps {
  className?: string;
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    data: strapiTherapy {
      title {
        title
        description
        image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }

      accordion {
        title
        description

        items: accordion {
          title: label
          content
        }

        image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }

      service {
        title
        text
        shortText
        reference {
          title
          text
          image {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

const TherapyPage = ({ data: { data } }) => {
  return (
    <Layout>
      <TopHeader title="Servicios / Terapia breve" />
      <SEO title="Terapia breve" />

      <TextSection
        {...data.title}
        image={data.title.image.localFile.childImageSharp.fluid}
      />

      <ImageAccordionSection
        {...data.accordion}
        image={data.accordion.image.localFile.childImageSharp.fluid}
      />

      <ServicesSection
        services={data.service.map((service) => {
          const payload = {
            title: service.title,
            content: service.text,
            shortText: service.shortText,
            image: service.image.localFile.childImageSharp.fluid,
            reference: null,
          };

          if (service.reference) {
            payload.reference = {
              image: service.reference?.image.localFile.childImageSharp.fluid,
              title: service.reference?.title,
              content: service.reference?.text,
              button: {
                label: service?.reference?.button?.label || 'Pide una cita',
                link: service?.reference?.button?.link || '/contacto',
              },
            };
          }

          return payload;
        })}
      />

      <BlogSectionControl />
    </Layout>
  );
};

export default TherapyPage;
