import React, { ReactNode } from "react";
import styled from "styled-components";
import { muiTheme } from "../../../../Theme/Theme";

interface SmallTitleProps {
  className?: string;
  children?: ReactNode;
}

const SmallTitleInner = (props: SmallTitleProps) => {
  const { children } = props;
  return <p className={props.className}> {children}</p>;
};

export const SmallTitle = styled(SmallTitleInner)`
  color: ${(props) => props.theme.color.white};
  font-size: 14px;
  margin: 10px 0;
  a {
    color: ${(props) => props.theme.color.white};
    font-weight: bold;
    padding: 10px 0;
  }

  &.bold {
    font-weight: bold;
  }

  ${muiTheme.breakpoints.down("sm")} {
    display: flex;
    flex-flow: column;
  }
`;
