import { AccordionBox, AccordionBoxProps } from '@hromada-mono/hromada';
import { Box, Container, Grid } from '@material-ui/core';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import React from 'react';
import { Section } from '../../components/Section/Section';

export interface ImageAccordionSectionProps extends AccordionBoxProps {
  image: FluidObject;
}

export const ImageAccordionSection = (props: ImageAccordionSectionProps) => {
  const { image, ...accordionBoxProps } = props;
  return (
    <Box {...props}>
      <Section className={props.className} darkBg>
        <Container>
          <Grid container spacing={4}>
            <Grid item md={3} sm={5} xs={12}>
              <Box width="100%">
                <GatsbyImage fluid={image} />
              </Box>
            </Grid>

            <Grid item md={9} sm={7} xs={12}>
              <AccordionBox {...accordionBoxProps} />
            </Grid>
          </Grid>
        </Container>
      </Section>
    </Box>
  );
};
