import {
  Button,
  Link,
  SEO,
  Steps,
  TextSection,
  TopHeader,
} from '@hromada-mono/hromada';
import { Box } from '@material-ui/core';
import { graphql } from 'gatsby';
import React from 'react';
import { FormSectionControl } from '../components/Home/FormSectionControl';
import Layout from '../components/Shared/Layout/Layout';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    data: strapiContactPage {
      title {
        title
        description
      }
      links {
        link
        label
      }
      steps {
        title
        steps {
          title
          content
          color
          image {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ContactPage = ({ data: { data } }) => {
  console.log(data.steps.steps);

  return (
    <Layout>
      <TopHeader title="Contacto" />
      <SEO title="Contacto" />

      <TextSection {...data.title} />

      <Box
        display="flex"
        justifyContent="space-evenly"
        marginTop="30px"
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
      >
        {data.links.map((link) => (
          <Link key={link.link} to={link.link}>
            <Button>{link.label}</Button>
          </Link>
        ))}
      </Box>

      <Steps
        title={data?.steps?.title}
        steps={data?.steps?.steps.map((strapiStep) => ({
          name: strapiStep.title,
          description: strapiStep.content,
          color: strapiStep.color,
          image: strapiStep?.image[0]?.localFile?.childImageSharp?.fluid,
        }))}
      />

      <FormSectionControl />
    </Layout>
  );
};

export default ContactPage;
