import Grid from '@material-ui/core/Grid';
import { TiSocialFacebook } from '@react-icons/all-files/ti/TiSocialFacebook';
import { TiSocialInstagram } from '@react-icons/all-files/ti/TiSocialInstagram';
import { TiSocialLinkedin } from '@react-icons/all-files/ti/TiSocialLinkedin';
import { TiSocialYoutube } from '@react-icons/all-files/ti/TiSocialYoutube';
import React from 'react';
import styled from 'styled-components';

export type SocialsProps = React.HTMLAttributes<HTMLElement>;

const Social = styled.a`
  width: 30px;
  height: 30px;
  background-color: ${(props) => props.theme.color.primaryDark};
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.color.white};
  font-size: 16px;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

const SocialLinks = [
  {
    name: 'Facebook',
    icon: <TiSocialFacebook />,
    url: 'https://www.facebook.com/Branislav-Hromada-Coach-Trainer-333163113838314',
  },
  {
    name: 'Instagram',
    icon: <TiSocialInstagram />,
    url: 'https://www.instagram.com/branohromada/',
  },

  {
    name: 'Youtube',
    icon: <TiSocialYoutube />,
    url: 'https://www.youtube.com/channel/UCRONPC7DRz6EFdSHq4KrP6w',
  },
  {
    name: 'Linkedin',
    icon: <TiSocialLinkedin />,
    url: 'https://www.linkedin.com/in/branislavhromada?originalSubdomain=sk',
  },
];

const SocialsInner: React.FC<SocialsProps> = (props: SocialsProps) => {
  return (
    <Grid className={props.className} container justify="space-between">
      {SocialLinks.map((link) => (
        <Social
          aria-label={link.name}
          key={link.url}
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {link.icon}
        </Social>
      ))}
    </Grid>
  );
};

export const Socials = styled(SocialsInner)<SocialsProps>`
  color: ${(props) => props.theme.color.primary};
  max-width: 150px;
`;
