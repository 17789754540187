import { ArticleListSection } from '@hromada-mono/hromada';
import { Box, BoxProps } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePostPath } from '../../lib/utils';
import { useNLastBlogPosts } from '../Shared/hooks/useNLastBlogPosts';

export interface BlogSectionControlProps extends BoxProps {}

export const BlogSectionControl = (props: BlogSectionControlProps) => {
  const { t } = useTranslation();
  const articles = useNLastBlogPosts(3);

  return (
    <Box {...props}>
      <ArticleListSection
        title={t('postsFromBlog.title')}
        articles={articles.map((article) => ({
          title: article.title,
          image: article.thumbnail.localFile.childImageSharp.fluid,
          description: article.teaser,
          link: {
            href: generatePostPath(article.title),
            label: t('buttons.viewMore'),
          },
        }))}
      />
    </Box>
  );
};
