import { theme } from '@hromada-mono/hromada';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
   html {
      font-size: 62.5%;
      @media screen and (max-width: 600px) {
        font-size: 50%;
      }
  }

	main {
		max-width: 100vw;
		overflow: hidden;
	}

  .swal2-popup {
    font-size: 1.5rem !important;
  }

  .swal2-styled.swal2-confirm {
    background-color: #ECA93F !important;
  }

  body {
    font-size: 1.6rem;
    font-family: 'Ubuntu', sans-serif;
    color: ${theme.color.primaryDark};
  }

  * {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: ${theme.color.primaryDark};
  }

  p {
    color: ${theme.color.gray};
    margin-bottom: 10px;
  }

  h1, h2, h3, h4 {
    color: ${theme.color.primaryDark};
  }

  h3 {
    font-size: 24px;
  }

  ul {
    margin-bottom: 10px;
    li {
      color: #727271;
    }
  }

  li {
    color: ${theme.color.gray};
    padding-top: 5px;
  }

   h4 {
     margin-bottom: 10px;
   }
  /*  Used for hiding form labels, to achieve 100% accessibility */
  .visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;
