import { Box } from '@material-ui/core';
import { Link } from 'gatsby';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { Button } from '../../Button/Primary/Button';

export interface ServiceReferenceProps {
  image?: FluidObject;
  title: string;
  content: string;
  button: {
    label: string;
    link: string;
  };
}

const ServiceReferenceInner = (props: ServiceReferenceProps) => {
  const { image, title, content, button } = props;
  const { t } = useTranslation('translations');

  return (
    <Box textAlign="center">
      {image && (
        <ImageWrapper>
          <GatsbyImage fluid={image} />
        </ImageWrapper>
      )}
      <TextWrapper>
        <h4>{title}</h4>
        <p>
          <ReactMarkdown children={content} />
        </p>
      </TextWrapper>
      <Link to={button.link}>
        <Button type="secondary">{button.label}</Button>
      </Link>
    </Box>
  );
};

const ImageWrapper = styled.div`
  max-width: 120px;
  width: 100%;
  margin: 20px auto;
  border-radius: 50%;
  overflow: hidden;
`;

const TextWrapper = styled.div`
  margin: 20px auto;
  text-align: center;
  width: 80%;
`;
export const ServiceReference = styled(ServiceReferenceInner)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  h4 {
    margin-bottom: 10px;
    font-size: 18px;
  }
`;
