import Grid from "@material-ui/core/Grid/Grid";
import GatsbyImage, { FluidObject } from "gatsby-image";
import React from "react";
import styled from "styled-components";
import { muiTheme } from "../../../Shared/Theme/Theme";

export interface TherapyItemProps {
  className?: string;
  name: string;
  description: string;
  number: number;
  color: string;
  image?: FluidObject;
}

const TherapyItemInner = (props: TherapyItemProps) => {
  const { name, description, number, color, image } = props;
  return (
    <Grid
      container
      className={props.className}
      sm={12}
      alignItems="center"
      justify="center"
    >
      <InnerItem
        container
        item
        sm={4}
        xs={12}
        wrap="wrap"
        justify="center"
        alignItems="center"
        direction="column"
      >
        <Textik customColor={color}>
          <Number>{number}</Number>
          <p>{name}</p>
        </Textik>
      </InnerItem>

      <InnerItem container item sm={4} xs={12} justify={"center"}>
        <ImageWrapper>
          <GatsbyImage fluid={image} />
        </ImageWrapper>
      </InnerItem>

      <InnerItem container item sm={4} xs={12}>
        <Description textAlign={number % 2 === 0 ? "left" : "right"}>
          {description}
        </Description>
      </InnerItem>
    </Grid>
  );
};

export const TherapyItem = styled(TherapyItemInner)`
  flex-direction: ${props => (props.number % 2 === 0 ? "row" : "row-reverse")};
  margin-bottom: 50px;
`;

const InnerItem = styled(Grid)``;

const Textik = styled.div<{
  customColor: string;
}>`
  font-size: 42px;
  font-weight: bold;

  p {
    color: ${props => props.customColor};
  }
  text-align: center;

  ${muiTheme.breakpoints.down("xs")} {
    font-size: 38px;
  }
`;

const Number = styled.p`
  font-size: 130px;
  margin-bottom: -5px;
  ${muiTheme.breakpoints.down("xs")} {
    font-size: 70px;
  }
`;

const Description = styled.p<{
  textAlign: string;
}>`
  text-align: ${props => props.textAlign};

  ${muiTheme.breakpoints.down("xs")} {
    text-align: center;
    margin-top: 20px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 250px;
  max-height: 250px;
  border-radius: 100%;
  margin: 0 15px;
`;
