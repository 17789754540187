import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import { SmallTitle } from "../../SmallTitle";

export interface LanguageSwitcherProps {
  className?: string;
}

const LanguageSwitcherInner = (props: LanguageSwitcherProps) => {
  const { t } = useTranslation("translations");
  return (
    <div className={props.className}>
      {/* //TODO: links na prepinanie jazyka */}
      <SmallTitle>
        <span>{t("footer.changeLanguageLabel")}</span>
        <div>
          <a href="https://branohromada.sk" target="_blank" rel="noopener noreferrer">
            Slovak{" "}
          </a>
          /
          <a href="https://branohromada.com" target="_blank" rel="noopener noreferrer">
            {" "}
            English{" "}
          </a>
          /
          <a href="https://branohromada.es" target="_blank" rel="noopener noreferrer">
            {" "}
            Spanish
          </a>
        </div>
      </SmallTitle>
    </div>
  );
};

export const LanguageSwitcher = styled(LanguageSwitcherInner)`
  span {
    margin-right: 10px;
  }
`;
