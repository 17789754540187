import { LetsStartSection } from '@hromada-mono/hromada';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { routes } from '../../../../src/lib/routes';
import { useNeedAdviceBoxImages } from './hooks/useNeedAdviceBoxImages';
import { useWhyThisJobBoxSections } from './hooks/useWhyThisJobBoxSections';
export interface LetsStartSectionProps {
  className?: string;
}

const LetsStartSectionInner = (props: LetsStartSectionProps) => {
  const { t } = useTranslation('translations');
  const images = useNeedAdviceBoxImages();

  const paragraphs: string[] = useMemo(
    () => t('niceToMeetYou.main.text', { returnObjects: true }),
    []
  );

  const posts = useWhyThisJobBoxSections();

  return (
    <LetsStartSection
      title={t('niceToMeetYou.title')}
      sideBanner={{
        image: images.person,
        title: t('niceToMeetYou.side.title'),
        phoneNumber: t('contact.phone'),
        openingHours: t('contact.available'),
        cta: {
          to: routes.contact.to,
          label: t('niceToMeetYou.side.button'),
        },
        bottomImage: images.logo,
      }}
      accordionBox={{
        title: paragraphs[0],
        description: paragraphs.slice(1, -1).join('\n'),
        items: posts,
      }}
      buttons={[
        {
          to: t('socials.linkedin.url'),
          label: t('socials.linkedin.title'),
        },
        {
          to: routes.whyWithMe.to,
          label: t('buttons.whyWithMe'),
        },
      ]}
    />
  );
};

export const LetsStartSectionControl = styled(LetsStartSectionInner)`
  max-width: 100vw;
  overflow: hidden;
`;
