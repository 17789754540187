import { Box } from '@material-ui/core';
import React from 'react';
import { AccordionItem as AccordionItemObject } from '../../models/Accordion/accordion-item.model';
import { AccordionItem } from './components/AccordionItem';

export interface AccordionElementProps {
  className?: string;
  items: AccordionItemObject[];
}

export const AccordionElement = (props: AccordionElementProps) => {
  const { items, ...boxProps } = props;

  return (
    <Box {...boxProps}>
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          markdownSource={item.content}
          htmlSource={item.html}
        />
      ))}
    </Box>
  );
};
