import React from 'react';
import { StrapiEvent } from '../../../models/Event/event';

export interface StrapiEventProviderProps {
  event: StrapiEvent.Event;
  children?: React.ReactNode | React.ReactNode[];
}

const EventContext = React.createContext<StrapiEvent.Event | null>(null);

export const useStrapiEvent = () => {
  const context = React.useContext(EventContext);

  if (context === null) {
    throw new Error('useStrapiEvent must be used within a StrapiEventProvider');
  }
  return context;
};

export const StrapiEventProvider = (props: StrapiEventProviderProps) => {
  const { children, event } = props;
  return (
    <EventContext.Provider value={event}>{children}</EventContext.Provider>
  );
};
