import { Container, Grid } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import {
  MostRequestedService,
  MostRequestedServiceProps,
} from '../../components/MostRequestedService/MostRequestedService';
import { SectionTitle } from '../../components/SectionTitle/SectionTitle';
import { theme } from '../../theme/theme';

export interface MostRequestedServicesProps {
  className?: string;
  title: string;
  services: MostRequestedServiceProps[];
}

const MostRequestedServicesInner = (props: MostRequestedServicesProps) => {
  const { title, services } = props;

  return (
    <div className={props.className}>
      <Container>
        <SectionTitle>{title}</SectionTitle>
        <Grid container spacing={8} direction="row">
          {services.map((service: any) => (
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              key={service.title}
              xs={12}
              md={4}
            >
              <MostRequestedService {...service} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export const MostRequestedServices = styled(MostRequestedServicesInner)`
  background: ${theme.color.sectionBg};
  padding: 40px 0;

  max-width: 100vw;
  overflow-x: hidden;
`;
