import { Box } from '@material-ui/core';
import React from 'react';
import {
  ButtonAndShortText,
  ButtonAndShortTextProps,
} from '../Button/ButtonAndShortText/ButtonAndShortText';

export interface SectionLinksProps {
  className?: string;
  links: ButtonAndShortTextProps[];
}

export const SectionLinks = (props: SectionLinksProps) => {
  const { links } = props;
  return (
    <Box
      marginTop="50px"
      display="flex"
      justifyContent="center"
      flexWrap="wrap"
      flexDirection={{
        xs: 'column',
        sm: 'row',
      }}
      paddingX={{
        xs: '0px',
        md: '100px',
      }}
    >
      {links.map((link) => (
        <Box
          marginX="10px"
          display="flex"
          key={link.buttonTitle}
          flexGrow="1"
          maxWidth={{
            xs: 'none',
            md: '400px',
          }}
        >
          <ButtonAndShortText
            buttonTitle={link.buttonTitle}
            buttonUrl={`${link.buttonUrl}`}
            buttonType={link.buttonType || 'primary'}
            shortText={link.shortText}
          />
        </Box>
      ))}
    </Box>
  );
};
