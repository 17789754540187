import { muiTheme, theme } from '@hromada-mono/hromada';
import { MuiThemeProvider } from '@material-ui/core';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from '../Theme/components/GlobalStyle';
import { Footer } from './components/Footer/Footer';
import { Header } from './components/Header/Header';

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={muiTheme}>
            <GlobalStyle />
            <Helmet>
              <html lang="en" />
            </Helmet>
            <Header />
            <main>{children}</main>
            <Footer />
          </MuiThemeProvider>
        </ThemeProvider>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
