import { Container, Grid } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import {
  ArticleCard,
  ArticleCardProps,
} from '../../components/ArticleCard/ArticleCard';
import { Section } from '../../components/Section/Section';
import { SectionTitle } from '../../components/SectionTitle/SectionTitle';

export interface BlogSectionProps {
  className?: string;
  title: string;
  articles: ArticleCardProps[];
}

const BlogSectionInner = (props: BlogSectionProps) => {
  const { title, articles } = props;

  return (
    <Section>
      <Container>
        <SectionTitle textAlign="left">{title}</SectionTitle>
        <Grid container spacing={4} direction="row">
          {articles.map((article) => (
            <Grid
              item
              key={article.title}
              container
              justify="center"
              alignItems="stretch"
              xs={12}
              sm={6}
              md={4}
            >
              <ArticleCard {...article} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export const ArticleListSection = styled(BlogSectionInner)``;
