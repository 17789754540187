import Container from "@material-ui/core/Container";
import { graphql, useStaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";
import React from "react";
import styled from "styled-components";
import { CaptainTitle } from "../../Shared/CaptainTitle/CaptainTitle";
import { Section } from "../../Shared/Layout/components/Section/Section";

export interface CaptainSectionProps {
  className?: string;
}

const CaptainSectionInner = (props: CaptainSectionProps) => {
  const { logoImage } = useStaticQuery(graphql`
    query CaptainImage {
      logoImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <Section className={props.className}>
      <Container>
        <LogoWrapper>
          <GatsbyImage
            style={{
              margin: "auto"
            }}
            fluid={logoImage.childImageSharp.fluid}
          />
        </LogoWrapper>
        <CaptainTitle color="dark" />
      </Container>
    </Section>
  );
};

export const CaptainSection = styled(CaptainSectionInner)``;

const LogoWrapper = styled.div`
  margin: auto;
  justify-content: center;
  max-width: 300px;
  height: 100%;
  margin-bottom: 70px;
`;
