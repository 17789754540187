import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { theme } from '../../../theme/theme';

export const TopSubTitle = styled.h3`
  color: ${theme.color.yellow};
`;

export const ContactBox = styled(Grid)`
  margin-bottom: 20px !important;
`;
export const TelNumber = styled.a`
  font-weight: bold;
  font-size: 2.4rem;
  margin-bottom: 10px;
  display: flex;
`;
