import { Container, Grid } from '@material-ui/core';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import React from 'react';
import { TextSection } from '../TextSection/TextSection';

export interface ReferencesSectionProps {
  title: string;
  description: string;
  references: {
    title: string;
    image: FluidObject;
  }[];
}

export const ReferencesSection = (props: ReferencesSectionProps) => {
  const { title, description, references } = props;
  return (
    <section>
      <Container>
        <TextSection title={title} description={description} />
        <div
          className="reference-grid"
          style={{
            marginTop: '30px',
          }}
        >
          <Grid container spacing={6}>
            {references.map((reference) => (
              <Grid
                key={reference.title}
                item
                xs={4}
                md={3}
                lg={2}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div
                  className="image"
                  style={{
                    width: '100%',
                  }}
                >
                  <GatsbyImage alt={reference.title} fluid={reference.image} />
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </Container>
    </section>
  );
};
