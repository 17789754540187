import { Container, Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TextSection } from '../../components';
import { StrapiEvent } from '../../models/Event/event';
import { generateEventRoute } from '../../utils/routes';
import { EventCard } from './components/EventCard';

export interface EventsSectionProps {
  header: {
    title: string;
    description: string;
  };
  viewMoreLabel: string;
  events: StrapiEvent.Event[];
}

const CardsWrapper = styled.section`
  margin-top: 30px;
`;

export const EventsSection = (props: EventsSectionProps) => {
  const { header, viewMoreLabel } = props;
  const { t, i18n } = useTranslation();

  return (
    <Container>
      <TextSection {...header} />
      <CardsWrapper>
        <Grid container spacing={4} direction="row">
          {props.events.map((event) => (
            <Grid
              item
              key={event.id}
              container
              justify="center"
              alignItems="stretch"
              xs={12}
              sm={6}
              md={4}
            >
              <EventCard
                link={{
                  to: generateEventRoute(event.slug, i18n.language),
                  label: viewMoreLabel,
                }}
                key={event.id}
                {...event}
              />
            </Grid>
          ))}
        </Grid>
      </CardsWrapper>
    </Container>
  );
};
